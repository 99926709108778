import { Doughnut } from 'react-chartjs-2';
import { Box, Card, CardContent, Divider, Typography, useTheme } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

interface IProps {
    title: Array<string>;
    value: Array<number>;
    titleColor: string;
}

const Cowchart = ({ title, value, titleColor }: IProps) => {
    const theme = useTheme();
    const amountValue = value[0] + value[1]
    const data = {
        datasets: [
            {
                data: [(amountValue - value[1]), (amountValue - value[0])],
                backgroundColor: ['#F4F5F9', titleColor],
                borderWidth: 8,
                borderColor: '#FFFFFF',
                hoverBorderColor: '#FFFFFF',
            }
        ],
        labels: [title[0], title[1]]
    };

    const options = {
        layout: { padding: 0 },
        legend: {
            display: false
        },
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
            backgroundColor: theme.palette.background.paper,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary,
        }
    };

    const devices = [
        {
            title: title[0],
            value: value[0],
            color: 'black'
        },
        {
            title: title[1],
            value: value[1],
            color: titleColor
        },
    ];

    return (
        <Card>
            <Box sx={{ padding: 1, fontWeight: 'bold', textAlign: 'center', backgroundColor: titleColor, color: 'white', fontSize: 22 }}>{title[0]}</Box>
            <Divider />
            <CardContent>
                <Box sx={{ height: 150, position: 'relative' }}>
                    <Doughnut data={data} options={options} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
                    {devices.map(({ color, title, value }) => (
                        <Box key={title} sx={{ p: 1, textAlign: 'center' }}>
                            <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="textPrimary" variant="body1">
                                {title}
                            </Typography>
                            <Typography sx={{ fontSize: 16, fontWeight: 'bold' }} style={{ color }} variant="h6">
                                {value}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </CardContent>
        </Card>
    );
};

export default Cowchart;