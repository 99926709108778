import { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { getFarm } from '../axios';
import { FarmContext } from '../Context/Farm';

export default function FarmSelect() {
    const { selectFarmId, handleSelectFarmId } = useContext<IFarmContext>(FarmContext);
    const [farm, setFarm] = useState<Array<IFarm> | undefined>(undefined);

    const handleChange = (event: SelectChangeEvent) => {
        handleSelectFarmId(event.target.value as string);
    };

    useEffect(() => {
        getFarm().then((res) => setFarm(res.data));
    }, []);

    return (
        <Box sx={{ padding: 0 }}>
            {farm &&
                <FormControl fullWidth>
                    <Select
                        onChange={handleChange}
                        size={'small'}
                        sx={{ borderRadius: 20, fontWeight: 'bold' }}
                        value={selectFarmId}
                    >
                        {farm.map((farm) => <MenuItem id={farm.id} value={farm.id}>{farm.name}</MenuItem>)}
                    </Select>
                </FormControl>
            }
        </Box>
    );
}