import { useEffect, useContext, useState } from 'react';
import { Box, Container, Grid, Link } from '@mui/material';
import Cowchart from '../components/dashboard/Cowchart';
import CowTable from '../components/Table/CowTable';
import { FarmContext } from '../Context/Farm';
import { getCowsByFarmId } from '../axios';


const Dashboard = () => {
    const { selectFarmId } = useContext<IFarmContext>(FarmContext);
    const [cowList, setCowList] = useState<Array<ICow> | undefined>(undefined);
    const [dashboardValue, setDashboardValue] = useState<any>({ readyPre: 0, estrus: 0, fertilization: 0, waitngPreDecision: 0, Pregenancy: 0, childbirthSoon: 0, calf: 0, focusManagement: 0 });

    useEffect(() => {
        if (selectFarmId) {
            getCowFromServer();
        }
    }, [selectFarmId]);

    const getCowFromServer = () => getCowsByFarmId(selectFarmId as string).then(res => {
        setCowList(res.data?.filter((calf: any) => calf.statusName === '송아지' || calf.statusName === '집중관리'));

        const readyPre = res.data.filter((cow: ICow) => cow.statusName === '수정준비').length;
        const estrus = res.data.filter((cow: ICow) => cow.statusName === '발정중').length;
        const waitngPreDecision = res.data.filter((cow: ICow) => cow.statusName === '임신판정검사대기').length;
        const fertilization = res.data.filter((cow: ICow) => cow.statusName === '수정완료').length;
        const Pregenancy = res.data.filter((cow: ICow) => cow.statusName === '임신').length;
        const childbirthSoon = res.data.filter((cow: ICow) => cow.statusName === '분만임박').length;
        const calf = res.data.filter((cow: ICow) => cow.statusName === '송아지').length;
        const focusManagement = res.data.filter((cow: ICow) => cow.statusName === '집중관리').length;
        // 추가 6개


        //json
        //{키 : 값}
        // 앞에 readyPre 키 (초기 키 readyPre)
        // 뒤에 readyPre 위 변수 값  (초기 값 0)
        setDashboardValue({
            readyPre: readyPre,
            estrus: estrus,
            fertilization: fertilization,
            waitngPreDecision: waitngPreDecision,
            Pregenancy: Pregenancy,
            childbirthSoon: childbirthSoon,
            calf: calf,
            focusManagement: focusManagement
        })
    });


    return (
        <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Link href='/dashboard/estrus' underline="none" >
                            <Cowchart title={[`임신준비`, '발정중']} value={[dashboardValue.readyPre, dashboardValue.estrus]} titleColor={'#FA6EA9'} />
                        </Link>
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <Link href='/dashboard/fertilization' underline="none" >
                            <Cowchart title={['수정완료', '임신판정검사대기']} value={[dashboardValue.fertilization, dashboardValue.waitngPreDecision]} titleColor={'#628CF7'} />
                        </Link>
                    </Grid>
                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <Link href='/dashboard/pregnancy' underline="none" >
                            <Cowchart title={['임신중', '분만임박']} value={[dashboardValue.Pregenancy, dashboardValue.childbirthSoon]} titleColor={'#F6B50D'} />
                        </Link>
                    </Grid>

                    <Grid item xl={3} lg={3} sm={6} xs={12}>
                        <Link href='/dashboard/calf' underline="none" >
                            <Cowchart title={['송아지', '집중관리']} value={[dashboardValue.calf, dashboardValue.focusManagement]} titleColor={'#1A7E48'} />
                            {/* <Cowchart title={['송아지', '집중관리']} value={[10, 10]} titleColor={'#1A7E48'} /> */}
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        <CowTable />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
};

export default Dashboard;