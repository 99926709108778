import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
export const Logo = styled((props): any => {

  return (
    <Box sx={{
      color: 'white',
      fontSize: '44px',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row'
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
        <HomeIcon sx={{ fontSize: 40 }} />
      </Box>
      <Box>Cowshell</Box>
    </Box>
  );
})``;