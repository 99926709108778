import { useState, useEffect, useContext } from 'react';
import Table from '@mui/material/Table';
import { styled } from '@mui/system';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import {
    deleteCowById, postCow, putCow, getCowStatus,
    getNsensorsByFarmId, getVsensorsByFarmId, getCowsByFarmId, getGroupsByFarmId
} from '../axios';
import { Link } from 'react-router-dom';
import {
    Box, Typography, Card,
    CardContent, InputAdornment,
    SvgIcon,
} from '@mui/material';
import { Search as SearchIcon } from '../icons/search';
import { FarmContext } from '../Context/Farm';
import TableSortLabel from '@mui/material/TableSortLabel';
import { changeDateToString } from '../utils/date';

interface IGroup {
    id: string,
    name: string,
    category: string
};

interface IVsensor {
    id: string,
    status: string,
    serial: string
};

interface INsensor {
    id: string,
    status: string,
    serial: string
};

interface ICow {
    id: string,
    identifyId: string,
    groupId: string,
    groupName: string,
    statusId: string,
    statusName: string,
    birthCnt: string,
    ageDay: string,
    nsensorId: string,
    nsensorSerial: string,
    vsensorId: string
    vsensorSerial: string,
    fertilization: string
};

interface IValues {
    identifyId: string,
    birthCnt: string,
    ageDay: string,
    fertilization: string
};

interface IPostData {
    identifyId: string,
    groupId: string,
    statusId: string,
    birthCnt: string,
    ageDay: string,
    nsensorId: string,
    vsensorId: string,
    farmId: string,
    fertilization: string
};

interface ICowStatus {
    id: string,
    name: string
};

const Root = styled('div')`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    text-align: left;
    padding: 8px;
    font-size: 15px;
    padding: 16px;
  }

  th {
    background-color: #F3F4F6;
    color: #374151;
    font-size: 15px;    
  }
`;

const Cows = () => {
    const [action, setAction] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [cowList, setCowList] = useState<Array<ICow> | undefined>(undefined);
    const [visibleCowList, setVisibleCowList] = useState<Array<ICow> | undefined>(undefined);
    const [status, setStatus] = useState<string>('');
    const [group, setGroup] = useState<string>('');
    const [groupList, setGroupList] = useState<Array<IGroup> | undefined>(undefined);
    const [nsensor, setNsensor] = useState<string>('');
    const [nsensorList, setNsensorList] = useState<Array<INsensor> | undefined>(undefined);
    const [vsensor, setVsensor] = useState<string>('');
    const [vsensorList, setVsensorList] = useState<Array<IVsensor> | undefined>(undefined);
    const [cowStatusList, setCowStatusList] = useState<Array<ICowStatus> | undefined>(undefined);
    const [updateCowId, setUpdateCowId] = useState<string>('');
    const [values, setValues] = useState<IValues>({
        identifyId: '',
        birthCnt: '',
        ageDay: '',
        fertilization: ''
    });

    const [searchText, setSearchText] = useState<string>('');
    const { selectFarmId } = useContext<IFarmContext>(FarmContext);

    useEffect(() => {
        init();
    }, [selectFarmId]);

    const init = () => {
        if (selectFarmId) {
            getCowFromServer();
            getGroupsByFarmId(selectFarmId as string).then((res) => {
                setGroupList(res.data)
            });
            getCowStatus().then((res) => {
                setCowStatusList(res.data)
            });
            // const timer = setInterval(() => {
            //     getCowFromServer();
            // }, 30000);
            // return () => clearTimeout(timer);
        }
    }

    const openCowForm = () => {
        setAction('create');
        setOpen(true);
        initFormData();
        getVsensorsByFarmId(selectFarmId as string).then((res) => {
            const vsensorListOptions = res.data;
            vsensorListOptions.push({ id: '', serial: '없음', status: '없음' });
            setVsensorList(vsensorListOptions);
        });
        getNsensorsByFarmId(selectFarmId as string).then((res) => {
            const nsensorListOptions = res.data;
            nsensorListOptions.push({ id: '', serial: '없음', status: '없음' });
            setNsensorList(nsensorListOptions);
        });
    };

    const initFormData = () => {
        setValues({
            identifyId: '',
            birthCnt: '',
            ageDay: '',
            fertilization: ''
        })
    };

    const closeCowForm = () => setOpen(false);
    const handleChange = (prop: keyof IValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const getCowFromServer = () => getCowsByFarmId(selectFarmId as string).then(res => {
        setCowList(res.data);
        setVisibleCowList(res.data);

    });

    const createCow = async (data: IPostData) => {

        try {
            const res = await postCow(data)

            if (res.data === 'success') {
                getCowFromServer();
                closeCowForm();
            }
        } catch (e: any) {
            console.log('Error', e)
        }
    };

    const deleteCow = async (id: string) => {
        const res = await deleteCowById(id)
        if (res.data === 'Delete success') {
            getCowFromServer();
        }
    };

    const updateCow = async (data: IPostData) => {
        try {
            const res = await putCow(data, updateCowId)
            if (res.data === 'Update success') {
                getCowFromServer();
                closeCowForm();
            }
        } catch (e: any) {
            console.log('Error', e)
        }
    };

    const clickEditButton = (cow: ICow) => {
        setUpdateCowId(cow.id);
        setGroup(cow.groupId);
        setNsensor(cow.nsensorId);
        setVsensor(cow.vsensorId);
        setStatus(cow.statusId);
        setAction('update');
        setOpen(true)
        setValues({
            identifyId: cow.identifyId,
            birthCnt: cow.birthCnt,
            ageDay: cow.ageDay,
            fertilization: cow.fertilization
        })
        getVsensorsByFarmId(selectFarmId as string).then((res) => {
            const vsensorListOptions = res.data;
            vsensorListOptions.push({ id: '', serial: '없음', status: '없음' });
            setVsensorList(vsensorListOptions);
        });
        getNsensorsByFarmId(selectFarmId as string).then((res) => {
            const nsensorListOptions = res.data;
            nsensorListOptions.push({ id: '', serial: '없음', status: '없음' });
            setNsensorList(nsensorListOptions);
        });
    };

    const handleSubmit = () => {
        if (action === 'create') {
            createCow({
                ...values, statusId: status, groupId: group, nsensorId: nsensor,
                vsensorId: vsensor, fertilization: values.fertilization, farmId: selectFarmId as string,
            })
        } else if (action === 'update') {
            updateCow({
                ...values, statusId: status, groupId: group, nsensorId: nsensor,
                vsensorId: vsensor, fertilization: values.fertilization, farmId: selectFarmId as string,
            })
        }
    };

    const changeSearchText = (e: any) => {
        setSearchText(e.target.value);

        const filterData = cowList?.filter((cow: ICow) => {
            let identifyId = false;
            let groupName = false;
            let statusName = false;
            let birthCnt = false;
            let ageDay = false;
            let nsensorSerial = false;
            let vsensorSerial = false;
            if (cow.identifyId) {
                identifyId = cow.identifyId.includes(e.target.value);
            }
            if (cow.groupName) {
                groupName = cow.groupName.includes(e.target.value);
            }
            if (cow.statusName) {
                statusName = cow.statusName.includes(e.target.value);
            }
            if (cow.birthCnt) {
                birthCnt = cow.birthCnt.includes(e.target.value);
            }
            if (cow.ageDay) {
                ageDay = cow.ageDay.includes(e.target.value)
            }
            if (cow.nsensorSerial) {
                nsensorSerial = cow.nsensorSerial.includes(e.target.value)
            }
            if (cow.vsensorSerial) {
                vsensorSerial = cow.vsensorSerial.includes(e.target.value)
            }
            return identifyId || groupName || statusName || birthCnt || ageDay || nsensorSerial || vsensorSerial;
        })
        setVisibleCowList(filterData);
    }
    // 데이터 저장할 상태와 정렬 순서 저장할 상태 정의
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<keyof ICow>('identifyId');

    // 데이터 정렬 함수 
    const sortData = (property: keyof ICow) => {
        if (!visibleCowList) return []; //  undefined인 경우 빈 배열 반환

        const isAscending = orderBy === property && order === 'asc';
        const sortedData = visibleCowList.slice().sort((a, b) => {
            const valueA = typeof a[property] === 'string' ? isNaN(parseInt(a[property], 10))
                ? a[property] : parseInt(a[property], 10) : a[property];
            const valueB = typeof b[property] === 'string' ? isNaN(parseInt(b[property], 10))
                ? b[property] : parseInt(b[property], 10) : b[property];
            if (valueA < valueB) return isAscending ? -1 : 1;
            if (valueA > valueB) return isAscending ? 1 : -1;
            return 0;
        });
        setVisibleCowList(sortedData);
        setOrder(isAscending ? 'desc' : 'asc');
        setOrderBy(property);
    }

    ////// 분만날짜 계산 ////////

    // const getBirthChildrenSoonDate = () => {
    //     // + 280일;
    //     if (!visibleCowList) return;

    //     if (visibleCowList.map((cow: ICow) => {
    //         cow.statusName === '수정완료' || cow.statusName === '임신' || cow.statusName === '분만임박'
    //     })

    //         statusName === '수정완료' || visibleCowList.statusName === '임신' || visibleCowList.statusName === '분만임박') {
    //         if (!visibleCowList.fertilization) return;
    //         const fertilizationDate = new Date(visibleCowList.fertilization.substring(0, 10));
    //         const birthChildrenSoonDate = new Date(fertilizationDate.setDate(fertilizationDate.getDate() + 280));
    //         console.log('birthChildrenSoonDate', birthChildrenSoonDate);
    //         return changeDateToString(birthChildrenSoonDate);
    //     }
    //     return;
    // }

    const getBirthChildrenSoonDate = (cow: ICow) => {
        if (!cow) return [];
        if (cow.statusName === '수정완료' || cow.statusName === '임신' || cow.statusName === '분만임박') {
            if (!cow.fertilization) return;
            const fertilizationDate = new Date(cow.fertilization.substring(0, 10));
            const birthChildrenSoonDate = new Date(fertilizationDate.setDate(fertilizationDate.getDate() + 280));
            console.log('birthChildrenSoonDate', birthChildrenSoonDate);
            return changeDateToString(birthChildrenSoonDate);
        }
        return;
    };

    return <div>
        <Box sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            m: -1
        }}>
            <Typography sx={{ m: 1 }} variant="h5">
                소 개체
            </Typography>
            <Box sx={{ m: 1 }}>
                <Button color="info"
                    variant="contained" onClick={openCowForm}>
                    소 생성
                </Button>
            </Box>
        </Box>

        <Box sx={{ mt: 3 }}>
            <Card>
                <CardContent>
                    <Box sx={{ maxWidth: 500 }}>
                        <TextField
                            fullWidth
                            value={searchText}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon
                                            color="action"
                                            fontSize="small"
                                        >
                                            <SearchIcon />
                                        </SvgIcon>
                                    </InputAdornment>
                                )
                            }}
                            onChange={changeSearchText}
                            placeholder="소 검색"
                            variant="outlined"
                        />
                    </Box>
                </CardContent>
            </Card>
        </Box>

        <Dialog open={open} onClose={closeCowForm}>
            <DialogTitle>소 개체 추가</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    추가할 소의 정보를 입력해주세요.
                </DialogContentText>
                <br />
                <TextField
                    autoFocus
                    margin="dense"
                    id="identifyId"
                    label="소 번호"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.identifyId}
                    onChange={handleChange('identifyId')}
                />
                <TextField
                    id="outlined-select-group"
                    margin="dense"
                    select
                    label="그룹"
                    value={group}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setGroup(event.target.value)}
                    fullWidth
                >
                    {groupList && groupList.map((group: IGroup) => (
                        <MenuItem key={group.id} value={group.id}>
                            {group.name} - {group.category}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    id="outlined-select-status"
                    margin="dense"
                    select
                    label="상태"
                    value={status}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setStatus(event.target.value)}
                    fullWidth
                >
                    {cowStatusList && cowStatusList.map((status: any) => (
                        <MenuItem key={status.id} value={status.id}>
                            {status.name}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    autoFocus
                    margin="dense"
                    id="birthCnt"
                    label="분만횟수"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.birthCnt}
                    onChange={handleChange('birthCnt')}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="ageDay"
                    label="소나이(월령)"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.ageDay}
                    onChange={handleChange('ageDay')}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="fertilization"
                    label="수정날짜"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.fertilization}
                    onChange={handleChange('fertilization')}
                />
                <TextField
                    id="outlined-select-nsensor"
                    margin="dense"
                    select
                    label="목걸이센서"
                    value={nsensor}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNsensor(event.target.value)}
                    fullWidth
                >
                    {nsensorList && nsensorList.map((nsensor: any) => (
                        <MenuItem key={nsensor.id} value={nsensor.id}>
                            {nsensor.serial}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    id="outlined-select-vsensor"
                    margin="dense"
                    select
                    label="분만센서"
                    value={vsensor}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setVsensor(event.target.value)}
                    fullWidth
                >
                    {vsensorList && vsensorList.map((vsensor: any) => (
                        <MenuItem key={vsensor.id} value={vsensor.id}>
                            {vsensor.serial}
                        </MenuItem>
                    ))}
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeCowForm()}>취소</Button>
                <Button onClick={handleSubmit}>추가</Button>
            </DialogActions>
        </Dialog>

        {visibleCowList &&
            <Box sx={{ mt: 3 }}>
                <TableContainer component={Paper}>
                    <Root sx={{ maxWidth: '100%' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === 'identifyId'}
                                            direction={order}
                                            onClick={() => sortData('identifyId')}>
                                            소ID
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === 'groupName'}
                                            direction={order}
                                            onClick={() => sortData('groupName')}>
                                            그룹이름
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === 'statusName'}
                                            direction={order}
                                            onClick={() => sortData('statusName')}>
                                            상태
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === 'birthCnt'}
                                            direction={order}
                                            onClick={() => sortData('birthCnt')}>
                                            분만횟수
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === 'ageDay'}
                                            direction={order}
                                            onClick={() => sortData('ageDay')}>소나이(
                                            월령
                                            )</TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === 'nsensorSerial'}
                                            direction={order}
                                            onClick={() => sortData('nsensorSerial')}>
                                            목걸이센서
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === 'vsensorSerial'}
                                            direction={order}
                                            onClick={() => sortData('vsensorSerial')}>
                                            분만센서
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === 'vsensorSerial'}
                                            direction={order}
                                            onClick={() => sortData('fertilization')}>
                                            수정날짜
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === 'vsensorSerial'}
                                            direction={order}
                                            onClick={() => sortData('vsensorSerial')}>
                                            분만 예정일
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {visibleCowList.map((cow: ICow) => {
                                    return (
                                        <TableRow
                                            key={cow.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="right">
                                                <Link
                                                    to={`/dashboard/cowDetail/${cow.id}`}
                                                    style={{ textDecoration: 'none' }}>
                                                    {cow.identifyId}
                                                </Link>
                                            </TableCell>
                                            <TableCell align="right">{cow.groupName}</TableCell>
                                            <TableCell align="right">{cow.statusName}</TableCell>
                                            <TableCell align="right">{cow.birthCnt}</TableCell>
                                            <TableCell align="right">{cow.ageDay}</TableCell>
                                            <TableCell align="right">{cow.nsensorSerial}</TableCell>
                                            <TableCell align="right">{cow.vsensorSerial}</TableCell>
                                            <TableCell align="right">{cow.fertilization}</TableCell>
                                            <TableCell align="right">{getBirthChildrenSoonDate(cow)}</TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    style={{ backgroundColor: "#628CF7", marginRight: '10px' }}
                                                    onClick={() => clickEditButton(cow)}>
                                                    수정
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    style={{ backgroundColor: "#f04141", marginRight: '10px' }}
                                                    onClick={() => deleteCow(cow.id)}>
                                                    삭제
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                )}
                            </TableBody>
                        </Table>
                    </Root>
                </TableContainer>
            </Box>
        }
    </div >
};
export default Cows;