import { getCowByGroupId } from '../axios';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import TableSortLabel from '@mui/material/TableSortLabel';

interface ICow {
    id: string,
    identifyId: string,
    groupId: string,
    groupName: string,
    statusId: string,
    statusName: string,
    birthCnt: string,
    ageDay: string,
    nsensorId: string,
    nsensorSerial: string,
    vsensorId: string
    vsensorSerial: string,
};


const Root = styled('div')`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    text-align: left;
    padding: 8px;
    font-size: 15px;
    padding: 16px;
  }

  th {
    background-color: #F3F4F6;
    color: #374151;
    font-size: 15px;    
  }
`;

const GroupDetail = () => {
    const { pathname } = useLocation();
    const groupId = pathname.split('/')[3];
    const [groupDetail, setGroupDetail] = useState<Array<ICow> | undefined>(undefined);
    const getGroupFromServer = () => getCowByGroupId(groupId).then(res => {
        setGroupDetail(res.data);
    })

    useEffect(() => {
        getGroupFromServer();
        const timer = setInterval(() => {
            getGroupFromServer();
        }, 30000);
        return () => clearTimeout(timer);
    }, []);

    // 데이터 저장할 상태와 정렬 순서 저장할 상태 정의
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<keyof ICow>('identifyId');
       // 데이터 정렬 함수 
    const sortData = (property: keyof ICow) => {
        if (!groupDetail) return []; //  undefined인 경우 빈 배열 반환

    const isAscending = orderBy === property && order === 'asc';
        const sortedData = groupDetail.slice().sort((a, b) => {
            const valueA = typeof a[property] === 'string' ? isNaN(parseInt(a[property], 10))
                ? a[property] : parseInt(a[property], 10) : a[property];
            const valueB = typeof b[property] === 'string' ? isNaN(parseInt(b[property], 10))
                ? b[property] : parseInt(b[property], 10) : b[property];     
        if (valueA < valueB) return isAscending ? -1 : 1;
        if (valueA > valueB) return isAscending ? 1 : -1;
        return 0;
    });
    setGroupDetail(sortedData);
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
    }


    return <div>
        <Box sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            m: -1
        }}>
            <Typography sx={{ m: 1 }} variant="h5">
                그룹 상세
            </Typography>
            <Box sx={{ m: 1 }}>
            </Box>
        </Box>

        {groupDetail &&
            <Box sx={{ mt: 3 }}>
                <TableContainer component={Paper}>
                    <Root sx={{ maxWidth: '100%' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="right">
                                        <TableSortLabel
                                        active={orderBy ==='identifyId'}
                                        direction={order}
                                        onClick={() => sortData('identifyId')}>
                                        소ID
                                        </TableSortLabel>
                                        </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                        active={orderBy ==='groupName'}
                                        direction={order}
                                        onClick={() => sortData('groupName')}>
                                        그룹이름
                                        </TableSortLabel>
                                        </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                        active={orderBy ==='statusName'}
                                        direction={order}
                                        onClick={() => sortData('statusName')}>
                                        상태
                                        </TableSortLabel>
                                        </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                        active={orderBy ==='birthCnt'}
                                        direction={order}
                                        onClick={() => sortData('birthCnt')}>
                                        분만횟수
                                        </TableSortLabel>
                                        </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                        active={orderBy ==='ageDay'}
                                        direction={order}
                                        onClick={() => sortData('ageDay')}>
                                        나이
                                        </TableSortLabel>
                                        </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                        active={orderBy ==='nsensorSerial'}
                                        direction={order}
                                        onClick={() => sortData('nsensorSerial')}>
                                        목걸이센서
                                        </TableSortLabel>
                                        </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                        active={orderBy ==='vsensorSerial'}
                                        direction={order}
                                        onClick={() => sortData('vsensorSerial')}>
                                        분만센서
                                        </TableSortLabel>
                                        </TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groupDetail.map((groupDetail: ICow) => (
                                    <TableRow
                                        key={groupDetail.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="right">{groupDetail.identifyId}</TableCell>
                                        <TableCell align="right">{groupDetail.groupName}</TableCell>
                                        <TableCell align="right">{groupDetail.statusName}</TableCell>
                                        <TableCell align="right">{groupDetail.birthCnt}</TableCell>
                                        <TableCell align="right">{groupDetail.ageDay}</TableCell>
                                        <TableCell align="right">{groupDetail.nsensorSerial}</TableCell>
                                        <TableCell align="right">{groupDetail.vsensorSerial}</TableCell>
                                        <TableCell align="right">
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Root>
                </TableContainer>
            </Box>
        }
    </div>
}


export default GroupDetail;