import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, IconButton, Button } from '@mui/material';
import { MHidden } from '../../components/@material-extend';
import { NavItem } from './nav-item';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import FarmSelect from '../../components/FarmSelect';


const DRAWER_WIDTH = 280;
const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));
// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

const items = [
  {
    href: '/dashboard',
    title: '홈'
  },
  {
    href: '/dashboard/event',
    title: '이벤트 관리'
  },
  {
    href: '/dashboard/cow',
    title: '개체 관리'
  },
  {
    href: '/dashboard/group',
    title: '그룹관리'
  },
  {
    href: '/dashboard/vsensor',
    title: '분만 센서'
  },
  {
    href: '/dashboard/nsensor',
    title: '목걸이 센서'
  },
  {
    href: '/dashboard/sensorStatus',
    title: '센서상태'
  },
  {
    href: '/dashboard/cowStatus',
    title: '소상태'
  },
  {
    href: '/dashboard/contact',
    title: '연락처'
  },
  // {
  //   href: '/login',

  //   title: 'Login'
  // },
  // {
  //   href: '/register',

  //   title: 'Register'
  // },
  {
    href: '/404',

    title: 'CCTV'
  },
  // {
  //   href: '/404',

  //   title: '보고서관리'
  // }
];

export default function DashboardNavbar({ onOpenSidebar }: any) {
  let navigate = useNavigate();
  const clickSetting = () => {
    alert('서비스 준비중입니다')
    navigate('/dashboard')
  }
  return (
    <RootStyle style={{ backgroundColor: '#F9FAFC', height: 50 }}>
      <Box sx={{ flexGrow: 1 }} />

      <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-around' }}>

        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ color: 'black' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <MHidden width="lgDown">
          <Stack direction="row" spacing={7}>
            {items.map((item) => (
              <Button
                key={item.title}
                href={item.href}
                sx={{ fontSize: '16px', color: '#95989e' }}>
                {item.title}
              </Button>
            ))}
          </Stack>
        </MHidden>
        <Stack direction="row" sx={{ alignItems: 'center', }}>

          <FarmSelect />

          {/* <Box sx={{ width: { xs: '60%', sm: '40%', lg: '80%' }, textAlign: 'center' }}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <OutlinedInput
                id="outlined-adornment-amount"
                // value={values.amount}
                // onChange={handleChange('amount')}
                startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                size="small"
                placeholder="검색"
                sx={{ borderRadius: 20 }}
              />
            </FormControl>
          </Box>
          <IconButton onClick={clickSetting} sx={{ color: 'black' }}>
            <SettingsIcon />
          </IconButton> */}
        </Stack>
      </Stack>
    </RootStyle>
  );
}
