export const getSelectedFarmId = () => {
    return JSON.parse(localStorage.getItem("cow-client-farmId") as string);
};

export const setSelectedFarmId = (farmId: string) => {
    localStorage.setItem("cow-client-farmId", JSON.stringify(farmId));
};

export const removeSelectedFarmId = () => {
    localStorage.removeItem("cow-client-farmId");
};
