import { useEffect, useContext, useState } from 'react';
import {
    Box, Typography, Card,
    CardContent, InputAdornment,
    SvgIcon, TextField
} from '@mui/material';
import CalfTable from "../components/Table/CalfTable";
import { FarmContext } from '../Context/Farm';
import {
    getCowStatus, getCowsByFarmId,
    getGroupsByFarmId
} from '../axios';
import { Search as SearchIcon } from '../icons/search';
import EstrusTable from '../components/Table/EstrusTable';



const Calfs = () => {
    const { selectFarmId } = useContext<IFarmContext>(FarmContext);
    const [groupList, setGroupList] = useState<Array<IGroup> | undefined>(undefined);
    const [cowStatusList, setCowStatusList] = useState<Array<ICowStatus> | undefined>(undefined);
    const [cowList, setCowList] = useState<Array<ICow> | undefined>(undefined);
    const [visibleCowList, setVisibleCowList] = useState<Array<ICow> | undefined>(undefined);
    const [searchText, setSearchText] = useState<string>('');

    useEffect(() => {
        init();
    }, [selectFarmId]);


    const init = () => {
        // debugger;
        if (selectFarmId) {
            getCowFromServer();
            getGroupsByFarmId(selectFarmId as string).then((res: any) => {
                setGroupList(res.data)
            });
            getCowStatus().then((res: any) => {
                setCowStatusList(res.data)
            });

        }
    }


    const changeSearchText = (e: any) => {
        setSearchText(e.target.value);

        const filterData = cowList?.filter((cow: ICow) => {

            let identifyId = false;
            let groupName = false;
            let statusName = false;
            let birthCnt = false;
            let ageDay = false;
            let nsensorSerial = false;
            let vsensorSerial = false;
            if (cow.identifyId) {
                identifyId = cow.identifyId.includes(e.target.value);
            }
            if (cow.groupName) {
                groupName = cow.groupName.includes(e.target.value);
            }
            if (cow.statusName) {
                statusName = cow.statusName.includes(e.target.value);
            }
            if (cow.birthCnt) {
                birthCnt = cow.birthCnt.includes(e.target.value);
            }
            if (cow.ageDay) {
                ageDay = cow.ageDay.includes(e.target.value)
            }
            if (cow.nsensorSerial) {
                nsensorSerial = cow.nsensorSerial.includes(e.target.value)
            }
            if (cow.vsensorSerial) {
                vsensorSerial = cow.vsensorSerial.includes(e.target.value)
            }
            return identifyId || groupName || statusName || birthCnt || ageDay || nsensorSerial || vsensorSerial;
        })
        setVisibleCowList(filterData);
    }

    const getCowFromServer = () => getCowsByFarmId(selectFarmId as string).then(res => {
        setCowList(res.data?.filter((calf: any) => calf.statusName === '수정준비' || calf.statusName === '발정중'));
        setVisibleCowList(res.data?.filter((calf: any) => calf.statusName === '수정준비' || calf.statusName === '발정중'));
    });


    return <>
        <Typography sx={{ m: 1 }} variant="h5">
            수정준비 & 발정중
        </Typography>
        <Box sx={{ mt: 3 }}>
            <Card>
                <CardContent>
                    <Box sx={{ maxWidth: 500 }}>
                        <TextField
                            fullWidth
                            value={searchText}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon
                                            color="action"
                                            fontSize="small"
                                        >
                                            <SearchIcon />
                                        </SvgIcon>
                                    </InputAdornment>
                                )
                            }}
                            onChange={changeSearchText}
                            placeholder="소 검색"
                            variant="outlined"
                        />
                    </Box>
                </CardContent>
            </Card>
        </Box>

        <EstrusTable visibleCowList={visibleCowList} />
    </>
}

export default Calfs;