import {
    ResponsiveContainer,
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    BarChart,
    LabelList
} from 'recharts';
import { useEffect, useContext, useState } from 'react';

import {
    Box, Typography, Card,
} from '@mui/material';
import { FarmContext } from '../Context/Farm';
import { getCowsByFarmId } from '../axios';



const StatusChart = () => {
    const { selectFarmId } = useContext<IFarmContext>(FarmContext);
    const [cowList, setCowList] = useState<Array<ICow> | undefined>(undefined);
    const [dashboardValue, setDashboardValue] = useState<any>({ readyPre: 0, estrus: 0, fertilization: 0, waitngPreDecision: 0, Pregenancy: 0, childbirthSoon: 0, calf: 0, focusManagement: 0 });

    useEffect(() => {
        if (selectFarmId) {
            getCowFromServer();
        }
    }, [selectFarmId]);


    const getCowFromServer = () => getCowsByFarmId(selectFarmId as string).then(res => {
        setCowList(res.data?.filter((calf: any) => calf.statusName === '송아지' || calf.statusName === '집중관리'));

        const readyPre = res.data.filter((cow: ICow) => cow.statusName === '수정준비').length;
        const estrus = res.data.filter((cow: ICow) => cow.statusName === '발정중').length;
        const waitngPreDecision = res.data.filter((cow: ICow) => cow.statusName === '임신판정검사대기').length;
        const fertilization = res.data.filter((cow: ICow) => cow.statusName === '수정완료').length;
        const Pregenancy = res.data.filter((cow: ICow) => cow.statusName === '임신').length;
        const childbirthSoon = res.data.filter((cow: ICow) => cow.statusName === '분만임박').length;
        const calf = res.data.filter((cow: ICow) => cow.statusName === '송아지').length;
        const focusManagement = res.data.filter((cow: ICow) => cow.statusName === '집중관리').length;
        //json
        //{키 : 값}
        // 앞에 readyPre 키 (초기 키 readyPre)
        // 뒤에 readyPre 위 변수 값  (초기 값 0)
        setDashboardValue({
            readyPre: readyPre,
            estrus: estrus,
            fertilization: fertilization,
            waitngPreDecision: waitngPreDecision,
            Pregenancy: Pregenancy,
            childbirthSoon: childbirthSoon,
            calf: calf,
            focusManagement: focusManagement
        })
    });
    const data = [
        { name: '수정준비', value: dashboardValue.readyPre },
        { name: '발정중', value: dashboardValue.estrus },
        { name: '판정검사대기', value: dashboardValue.waitngPreDecision },
        { name: '수정완료', value: dashboardValue.fertilization },
        { name: '임신', value: dashboardValue.Pregenancy },
        { name: '분만임박', value: dashboardValue.childbirthSoon },
        { name: '송아지', value: dashboardValue.calf },
        { name: '집중관리', value: dashboardValue.focusManagement },
    ];
    return <div>
        <Typography sx={{ m: 1, marginBottom: '20px' }} variant="h5">
            상태별 개체 현황
        </Typography >
        <Box style={{ marginTop: '20px', border: '1px solid #ddd' }}>
            <BarChart width={1400} height={600} data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#4e73c2" barSize={50} >
                    <LabelList dataKey="value" position="top" fontSize="25px" fontWeight="bold" />
                </Bar>
            </BarChart>
        </Box>
    </div>
};

export default StatusChart;