import axios from 'axios';

const domain = 'https://api.cowcare.net';
// const domain = 'http://localhost:8024';


export const getCowById = (id: string) => {
    return axios.get(`${domain}/cow/${id}`);
}

export const postCow = (data: {
    identifyId: string,
    groupId: string,
    statusId: string,
    birthCnt: string,
    ageDay: string,
    nsensorId: string,
    vsensorId: string,
    farmId: string,
    fertilization: string

}) => {
    return axios.post(`${domain}/cow`, data);
}

export const deleteCowById = (id: string) => {
    return axios.delete(`${domain}/cow/${id}`)
}

//todo-4 파라미터에 수정날짜 자료형 추가
//todo-5 백엔드 put 으로 받는 곳으로 이동 백앤드 작업
export const putCow = (data: {
    groupId: string,
    statusId: string,
    birthCnt: string,
    ageDay: string,
    nsensorId: string,
    vsensorId: string
    farmId: string,
    fertilization: string,
}, cowId: string) => {
    return axios.put(`${domain}/cow/${cowId}`, data);
}

export const getCowsByFarmId = (farmId: string) => {
    return axios.get(`${domain}/cow/farm/${farmId}`);
}

export const getEventById = (cowId: string) => {
    return axios.get(`${domain}/event/${cowId}`);
}

export const postEvent = (data: {
    cowId: string,
    date: string,
    eventName: string,
    description: string,
    farmId: string,
}) => {
    return axios.post(`${domain}/event`, data);
}

export const deleteEventById = (id: string) => {
    return axios.delete(`${domain}/event/${id}`)
}

export const putEvent = (data: {
    id: String,
    cowId: String,
    date: String,
    eventName: String,
    description: String,
    farmId: string,
}, id: string) => {
    return axios.put(`${domain}/event/${id}`, data);
}


export const getVsensorById = (vsensorId: string, selectFarmId: string) => {
    return axios.get(`${domain}/vsensor/${vsensorId}/${selectFarmId}`)
}

export const postVsensor = (data: {
    serial: string,
    sensorStatusId: string,
    daysInReport: string,
    detail: string,
    alarmActive: string,
    sensorId: string,
    farmId: string,
}) => {
    return axios.post(`${domain}/vsensor`, data);
}

export const deleteVsensorById = (id: string) => {
    return axios.delete(`${domain}/vsensor/${id}`)
}

export const putVsensor = (data: {
    serial: string,
    sensorStatusId: string,
    daysInReport: string,
    detail: string,
    alarmActive: string,
    sensorId: string,
    farmId: string,
}, vsensorId: string) => {
    return axios.put(`${domain}/vsensor/${vsensorId}`, data);
}


export const getNsensorListByCowId = () => {
    return axios.get(`${domain}/nsensor/nsensorList`);
}

export const getVsensorListByCowId = () => {
    return axios.get(`${domain}/vsensor/vsensorList`);
}

export const getNsensorById = (nsensorId: string) => {
    return axios.get(`${domain}/nsensor/${nsensorId}`)
}

export const postNsensor = (data: {
    serial: string,
    sensorStatusId: string,
    daysInReport: string,
    detail: string,
    alarmActive: string,
    sensorId: string,
    farmId: string,
}) => {
    return axios.post(`${domain}/nsensor`, data);
}

export const deleteNsensorById = (id: string) => {
    return axios.delete(`${domain}/nsensor/${id}`)
}

export const putNsensor = (data: {
    serial: string,
    sensorStatusId: string,
    daysInReport: string,
    detail: string,
    alarmActive: string,
    sensorId: string,
    farmId: string,
}, nsensorId: string) => {
    return axios.put(`${domain}/nsensor/${nsensorId}`, data);
}

export const getSensorStatus = () => {
    return axios.get(`${domain}/sensor/status`);
}

export const getNsensorDataById = (nsensorId: string) => {
    return axios.get(`${domain}/nsensor/data/${nsensorId}`)
}

export const getVsensorDataById = (vsensorId: string) => {
    return axios.get(`${domain}/vsensor/data/${vsensorId}`)
}

export const getVsensorDataByIdAndDate = (vsensorId: string, startDate: string, endDate: string) => {
    return axios.get(`${domain}/vsensor/data/${vsensorId}/${startDate}/${endDate}`)
}

export const getNsensorDataByIdAndDate = (nsensorId: string, startDate: string, endDate: string) => {
    return axios.get(`${domain}/nsensor/data/${nsensorId}/${startDate}/${endDate}`)
}

// dede



export const postGroup = (data: {
    id: string,
    name: string,
    category: string,
    farmId: string,
}) => {
    return axios.post(`${domain}/group`, data);
}

export const putGroup = (data: {
    name: string,
    category: string,
    farmId: string,
}, id: string) => {
    return axios.put(`${domain}/group/${id}`, data);
}

export const deleteByGroupId = (id: string) => {
    return axios.delete(`${domain}/group/${id}`)
}

export const getCowStatus = () => {
    return axios.get(`${domain}/cow/status`)
}

export const postCowStatus = (data: {
    name: string
}) => {
    return axios.post(`${domain}/cow/status`, data)
}

export const putCowStatus = (data: {
    name: string
}, id: string) => {
    return axios.put(`${domain}/cow/status/${id}`, data);
}

export const deleteByCowStatus = (id: string) => {
    return axios.delete(`${domain}/cow/status/${id}`)
}

export const postSensorStatus = (data: {
    name: string
}) => {
    return axios.post(`${domain}/sensor/status`, data)
}

export const putSensorStatus = (data: {
    name: string
}, id: string) => {
    return axios.put(`${domain}/sensor/status/${id}`, data);
}

export const deleteBySensorStatus = (id: string) => {
    return axios.delete(`${domain}/sensor/status/${id}`)
}

export const postContact = (data: {
    name: string,
    phoneNumber: string,
    isActive: string,
    farmId: string,
}) => {
    return axios.post(`${domain}/contact`, data);
}

export const putContact = (data: {
    name: string,
    phoneNumber: string,
    isActive: string,
    farmId: string,
}, id: string) => {
    return axios.put(`${domain}/contact/${id}`, data);
}

export const deleteByIdContact = (id: string) => {
    return axios.delete(`${domain}/contact/${id}`);
}

export const getCowByGroupId = (id: string) => {
    return axios.get(`${domain}/cow/group/${id}`);
}

export const getDashBoardByFarmId = (FarmId: string) => {
    return axios.get(`${domain}/cow/group/${FarmId}`);
}

export const getContactsByFarmId = (farmId: string) => {
    return axios.get(`${domain}/contact/farm/${farmId}`);
}

export const getEventByFarmId = (farmId: string) => {
    return axios.get(`${domain}/event/farm/${farmId}`);
}

export const getGroupsByFarmId = (farmId: string) => {
    return axios.get(`${domain}/group/farm/${farmId}`);
}

export const getNsensorsByFarmId = (farmId: string) => {
    return axios.get(`${domain}/nsensor/farm/${farmId}`);
}

export const getVsensorsByFarmId = (farmId: string) => {
    return axios.get(`${domain}/vsensor/farm/${farmId}`);
}

export const getFarm = () => {
    return axios.get(`${domain}/farm`);
}

