import { useLocation, useNavigate } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react';
import Table from '@mui/material/Table';
import { styled } from '@mui/system';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import Stepper from '../components/Stepper';
import VsensorChart from '../components/VsensorChart';
import NsensorChart from '../components/NsensorChart';
import { Box, CardContent, CardHeader, Card, Divider, Grid, Typography } from '@mui/material';
import { getCowById, deleteCowById, getNsensorsByFarmId, getVsensorsByFarmId, getCowStatus, putCow, getEventById, deleteEventById, postEvent, putEvent, getNsensorDataById, getVsensorDataById, getGroupsByFarmId } from '../axios';
import { FarmContext } from '../Context/Farm';
import { changeDateToString } from '../utils/date';

interface IGroup {
    id: string,
    name: string
};
interface IVsensor {
    id: string,
    status: string,
    serial: string
};
interface INsensor {
    id: string,
    serial: string
};
interface ICow {
    id: string,
    identifyId: string,
    groupId: string,
    groupName: string,
    statusId: string,
    statusName: string,
    birthCnt: string,
    ageDay: string,
    nsensorId: string,
    nsensorSerial: string,
    vsensorId: string
    vsensorSerial: string,
    fertilization: string
};
interface IValues {
    identifyId: string,
    birthCnt: string,
    ageDay: string,
    fertilization: string
};
interface IPostData {
    identifyId: string,
    groupId: string,
    statusId: string,
    birthCnt: string,
    ageDay: string,
    nsensorId: string,
    vsensorId: string,
    farmId: string,
    fertilization: string
};

interface IEvent {
    id: string,
    cowId: string,
    date: string,
    eventName: string,
    description: string,
    cowIdentifyId: string,
};

interface ICowStatus {
    id: string,
    name: string
}

//이벤트
interface IEventValues {
    id: string,
    eventName: string,
    date: string,
    description: string
};

interface IEventPostData {
    id: string,
    cowId: string,
    date: string,
    eventName: string,
    description: string,
    farmId: string,
};

//그래프
interface ISeries {
    name: string;
    type: string;
    data: Array<number>;
}

const Root = styled('div')`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    text-align: left;
    padding: 8px;
    font-size: 15px;
    padding: 16px;
  }

  th {
    background-color: #F3F4F6;
    color: #374151;
    font-size: 15px;    
  }
`;

const CowDetail = () => {
    const { pathname } = useLocation();
    let navigate = useNavigate();
    const cowId = pathname.split('/')[3];
    const [open, setOpen] = useState<boolean>(false);
    const [cowDetail, setCowDetail] = useState<ICow | undefined>(undefined);
    const [group, setGroup] = useState<string>('');
    const [groupList, setGroupList] = useState<Array<IGroup> | undefined>(undefined);
    const [nsensor, setNsensor] = useState<string>('');
    const [nsensorList, setNsensorList] = useState<Array<INsensor> | undefined>(undefined);
    const [vsensor, setVsensor] = useState<string>('');
    const [vsensorList, setVsensorList] = useState<Array<IVsensor> | undefined>(undefined);
    const [cowStatusList, setCowStatusList] = useState<Array<ICowStatus> | undefined>(undefined);
    const [cowStatus, setCowStatus] = useState<string>('');

    const [values, setValues] = useState<IValues>({
        identifyId: '',
        birthCnt: '',
        ageDay: '',
        fertilization: '',
    });
    const { selectFarmId } = useContext<IFarmContext>(FarmContext);


    const [nbattery, setNBattery] = useState<string | undefined>(undefined);
    const [nrssi, setNRssi] = useState<string | undefined>(undefined);
    const [nsensorVer, setNSensorVer] = useState<string | undefined>(undefined);
    const [nmacAddr, setNMacAddr] = useState<string | undefined>(undefined);

    const [vbattery, setVBattery] = useState<string | undefined>(undefined);
    const [vrssi, setVRssi] = useState<string | undefined>(undefined);
    const [vsensorVer, setVSensorVer] = useState<string | undefined>(undefined);
    const [vmacAddr, setVMacAddr] = useState<string | undefined>(undefined);

    const [nSeries, setNSeries] = useState<Array<ISeries>>([]);
    const [nLabels, setNLabels] = useState<Array<string>>([]);
    const [vSeries, setVSeries] = useState<Array<ISeries>>([]);
    const [vLabels, setVLabels] = useState<Array<string>>([]);

    // todo-1 수정날자 state 변수 만들기

    const closeCowForm = () => setOpen(false)
    const handleChange = (prop: keyof IValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const getCowFromServer = () => getCowById(cowId).then(res => {
        setCowDetail(res.data);
    });

    const step = () => {
        if (cowDetail === undefined) return;
        const step = cowDetail.statusName;
        if (step === '수정 준비') {
            return 0;
        } else if (step === '수정 완료') {
            return 1;
        } else if (step === '임신') {
            return 2;
        } else if (step === '분만 1달전') {
            return 3;
        }
        else if (step === '분만') {
            return 4;
        }
    }

    const deleteCow = async (id: string) => {
        const res = await deleteCowById(id)
        if (res.data === 'Delete success') {
            getCowFromServer();
            navigate('/dashboard/cow');
        }
    };
    // todo-3 IPostData 안에 수정날짜 자료형 추가
    const updateCow = async (data: IPostData) => {
        try {
            const res = await putCow(data, cowId)
            if (res.data === 'Update success') {
                getCowFromServer();
                closeCowForm();
            }
        } catch (e: any) {
            console.log('Error', e)
        }
    };

    const handleSubmit = () => {
        updateCow({ ...values, statusId: cowStatus, groupId: group, nsensorId: nsensor, vsensorId: vsensor, fertilization: values.fertilization, farmId: selectFarmId as string })
    };

    const clickEditButton = (cow: ICow) => {
        setGroup(cow.groupId);
        setCowStatus(cow.statusId);
        setNsensor(cow.nsensorId);
        setVsensor(cow.vsensorId);
        setOpen(true)
        setValues({
            identifyId: cow.identifyId,
            birthCnt: cow.birthCnt,
            ageDay: cow.ageDay,
            fertilization: cow.fertilization
        });
    };

    const [EventAction, setEventAction] = useState<string>('');
    const [EventOpen, setEventOpen] = useState<boolean>(false);
    const [eventList, setEventList] = useState<Array<IEvent> | undefined>(undefined);
    const [updateEventId, setUpdateEventId] = useState<string>('');
    const [EventValues, setEventValues] = useState<IEventValues>({
        id: '',
        eventName: '',
        date: '',
        description: ''
    });


    const openEventForm = () => {
        setEventAction('EventCreate');
        setEventOpen(true);
        initEventFormData();
    };

    const initEventFormData = () => {
        setEventValues({
            id: '',
            eventName: '',
            date: '',
            description: ''
        })
    };

    const closeEventForm = () => setEventOpen(false);


    const EventHandleChange = (prop: keyof IEventValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setEventValues({ ...EventValues, [prop]: event.target.value });
    };


    useEffect(() => {
        init();
    }, [selectFarmId]);

    const init = () => {
        if (selectFarmId) {
            getCowFromServer();
            getEventFromServer();
            getGroupsByFarmId(selectFarmId as string).then((res) => {
                setGroupList(res.data)
            });
            getCowStatus().then((res) => {
                setCowStatusList(res.data)
            });
        }
    }

    useEffect(() => {
        if (!cowDetail) return;
        nsensorDataUpdate(cowDetail?.nsensorId);
        vsensorDataUpdate(cowDetail?.vsensorId);
        getNsensorsByFarmId(selectFarmId as string).then((res) => {
            const nsensorListOptions = res.data;
            nsensorListOptions.push({ id: '', serial: '없음', status: '없음' });
            setNsensorList(nsensorListOptions);
        });
        getVsensorsByFarmId(selectFarmId as string).then((res) => {
            const vsensorListOptions = res.data;
            vsensorListOptions.push({ id: '', serial: '없음', status: '없음' });
            setVsensorList(vsensorListOptions);
        });
        const timer = setInterval(() => {
            nsensorDataUpdate(cowDetail?.nsensorId);
            vsensorDataUpdate(cowDetail?.vsensorId);
            getCowFromServer();
            getEventFromServer();
        }, 30000);
        return () => clearTimeout(timer);
    }, [cowDetail]);

    const getEventFromServer = () => getEventById(cowId).then((res) => {
        setEventList(res.data);
    });

    const deleteEvent = async (id: string) => {
        const res = await deleteEventById(id)
        if (res.data === 'Delete success') {
            getEventFromServer();
        }
    };

    const EventClickEditButton = (event: IEvent) => {
        setUpdateEventId(event.id);
        setEventAction('EventUpdate');
        setEventOpen(true)
        setEventValues({
            id: event.id,
            eventName: event.eventName,
            date: event.date,
            description: event.description
        })
    };

    const eventHandleSubmit = () => {
        if (EventAction === 'EventCreate') {
            createEvent({ ...EventValues, cowId: cowId, farmId: selectFarmId as string })
        } else if (EventAction === 'EventUpdate') {
            updateEvent({ ...EventValues, cowId: cowId, farmId: selectFarmId as string })
        }
    };

    const createEvent = async (data: IEventPostData) => {
        try {
            const res = await postEvent(data)
            if (res.data === 'success') {
                getEventFromServer();
                closeEventForm();
            }
        } catch (e: any) {
            console.log('Error', e)
        }
    };

    const updateEvent = async (data: IEventPostData) => {
        try {
            const res = await putEvent(data, updateEventId)
            if (res.data === 'Update success') {
                getEventFromServer();
                closeEventForm();
            }
        } catch (e: any) {
            console.log('Error', e)
        }
    };


    const nsensorDataUpdate = async (nsensorId: string) => {
        const nsensrData = await getNsensorDataById(nsensorId);
        setNSeries([
            {
                name: 'temp',
                type: 'line',
                data: nsensrData.data.map((data: any) => Number(data.temp))
            },
            {
                name: 'hPa',
                type: 'line',
                data: nsensrData.data.map((data: any) => Number(data.hPa)),
            },
            {
                name: 'vector',
                type: 'area',
                data: nsensrData.data.map((data: any) => Number(data.vector))
            }
        ]);
        setNLabels(
            nsensrData.data.map((data: any) => data.krTime)
        );
        setNBattery(nsensrData.data[0].battery);
        setNRssi(nsensrData.data[0].rssi);
        setNSensorVer(nsensrData.data[0].sensor_ver);
        setNMacAddr(nsensrData.data[0].macAddr);

    }

    const vsensorDataUpdate = async (vsensorId: string) => {
        const vsensrData = await getVsensorDataById(vsensorId);

        setVSeries([
            {
                name: 'temp',
                type: 'line',
                data: vsensrData.data.map((data: any) => Number(data.temp))
            },
            {
                name: 'hPa',
                type: 'line',
                data: vsensrData.data.map((data: any) => Number(data.hPa)),
            },
            {
                name: 'vector',
                type: 'area',
                data: vsensrData.data.map((data: any) => Number(data.vector))
            }
        ]);
        setVLabels(
            vsensrData.data.map((data: any) => data.krTime)
        );
        setVBattery(vsensrData.data[0].battery);
        setVRssi(vsensrData.data[0].rssi);
        setVSensorVer(vsensrData.data[0].sensor_ver);
        setVMacAddr(vsensrData.data[0].macAddr);

    }

    ////// 분만날짜 계산 ////////

    const getBirthChildrenSoonDate = () => {
        // + 280일;
        if (!cowDetail) return;
        if (cowDetail.statusName === '수정완료' || cowDetail.statusName === '임신' || cowDetail.statusName === '분만임박') {
            if (!cowDetail.fertilization) return;
            const fertilizationDate = new Date(cowDetail.fertilization.substring(0, 10));
            const birthChildrenSoonDate = new Date(fertilizationDate.setDate(fertilizationDate.getDate() + 280));
            console.log('birthChildrenSoonDate', birthChildrenSoonDate);
            return changeDateToString(birthChildrenSoonDate);
        }
        return;
    }


    return <div>
        <Box sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            m: -1,
        }}>
            <Typography sx={{ m: 1 }} variant="h5">
                소 개체 상세
            </Typography>
            <Box sx={{ m: 1 }}>
            </Box>
        </Box>
        <Box sx={{ mt: 3 }} />
        <Stepper step={step()}></Stepper>

        <Dialog open={open} onClose={closeCowForm}>
            <DialogTitle>{values.identifyId}의 정보 수정</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    수정할 소의 정보를 입력해주세요.
                </DialogContentText>
                <br />
                <TextField
                    id="outlined-select-group"
                    margin="dense"
                    select
                    label="그룹"
                    value={group}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setGroup(event.target.value)}
                    fullWidth
                >
                    {groupList && groupList.map((group: any) => (
                        <MenuItem key={group.id} value={group.id}>
                            {group.name}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    id="status"
                    margin="dense"
                    label="상태"
                    value={cowStatus}
                    select
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCowStatus(event.target.value)}
                    fullWidth
                >
                    {cowStatusList && cowStatusList.map((status: any) => (
                        <MenuItem key={status.id} value={status.id}>
                            {status.name}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    autoFocus
                    margin="dense"
                    id="birthCnt"
                    label="분만횟수"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.birthCnt}
                    onChange={handleChange('birthCnt')}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="ageDay"
                    label="소나이(월령)"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.ageDay}
                    onChange={handleChange('ageDay')}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="fertilization"
                    label="수정날짜"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.fertilization}
                    onChange={handleChange('fertilization')}
                />
                <TextField
                    id="outlined-select-nsensor"
                    margin="dense"
                    select
                    label="목걸이센서"
                    value={nsensor}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNsensor(event.target.value)}
                    fullWidth
                >
                    {nsensorList && nsensorList.map((nsensor: any) => (
                        <MenuItem key={nsensor.id} value={nsensor.id}>
                            {nsensor.serial}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    id="outlined-select-vsensor"
                    margin="dense"
                    select
                    label="분만센서"
                    defaultValue={vsensor}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setVsensor(event.target.value)}
                    fullWidth
                >
                    {vsensorList && vsensorList.map((vsensor: any) => (
                        <MenuItem key={vsensor.id} value={vsensor.id}>
                            {vsensor.serial}
                        </MenuItem>
                    ))}
                </TextField>
                {/* todo-2 수정날짜 text 입력넣기 state 변수에*/}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeCowForm()}>취소</Button>
                <Button onClick={() => handleSubmit()}>추가</Button>
            </DialogActions>
        </Dialog>

        {cowDetail &&
            <Box sx={{ mt: 3 }}>
                <TableContainer component={Paper}>
                    <Root sx={{ maxWidth: '100%' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="right">소ID</TableCell>
                                    <TableCell align="right">그룹이름</TableCell>
                                    <TableCell align="right">상태</TableCell>
                                    <TableCell align="right">분만횟수</TableCell>
                                    <TableCell align="right">소나이(월령)</TableCell>
                                    <TableCell align="right">목걸이센서</TableCell>
                                    <TableCell align="right">분만센서</TableCell>
                                    <TableCell align="right">수정날짜</TableCell>
                                    <TableCell align="right">분만날짜</TableCell>
                                    <TableCell align="right"></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    key={cowDetail.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="right">{cowDetail.identifyId}</TableCell>
                                    <TableCell align="right">{cowDetail.groupName}</TableCell>
                                    <TableCell align="right">{cowDetail.statusName}</TableCell>
                                    <TableCell align="right">{cowDetail.birthCnt}</TableCell>
                                    <TableCell align="right">{cowDetail.ageDay}</TableCell>
                                    <TableCell align="right">{cowDetail.nsensorSerial}</TableCell>
                                    <TableCell align="right">{cowDetail.vsensorSerial}</TableCell>
                                    <TableCell align="right">{cowDetail.fertilization}</TableCell>
                                    <TableCell align="right">{getBirthChildrenSoonDate()}</TableCell>
                                    <TableCell align="right">
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{ backgroundColor: "#628CF7", marginRight: '10px' }}
                                            onClick={() => clickEditButton(cowDetail)}>
                                            수정
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            style={{ backgroundColor: "#f04141", marginRight: '10px' }}
                                            onClick={() => deleteCow(cowDetail.id)}>
                                            삭제
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Root>
                </TableContainer>
            </Box>
        }
        {/* 이벤트 부분 */}
        <Box sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            m: -1,
            mt: 3
        }}>
            <Box sx={{ m: 1 }}>
                <Button variant="contained" onClick={openEventForm}>
                    이벤트 생성
                </Button>
            </Box>
        </Box>
        <Box sx={{ mt: 1 }} />
        <Dialog open={EventOpen} onClose={closeEventForm}>
            {(eventList && eventList.length !== 0) &&
                <DialogTitle>이벤트 생성</DialogTitle>}
            <DialogContent>
                <DialogContentText>
                    추가할 이벤트의 정보를 입력해주세요.
                </DialogContentText>
                <br />
                <TextField
                    autoFocus
                    margin="dense"
                    id="date"
                    label="날짜"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={EventValues.date}
                    onChange={EventHandleChange('date')}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="eventName"
                    label="이벤트이름"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={EventValues.eventName}
                    onChange={EventHandleChange('eventName')}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="description"
                    label="설명"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={EventValues.description}
                    onChange={EventHandleChange('description')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeEventForm()}>취소</Button>
                <Button onClick={() => eventHandleSubmit()}>추가</Button>
            </DialogActions>
        </Dialog>

        {eventList &&
            <Box sx={{ mt: 3 }}>
                <TableContainer component={Paper}>
                    <Root sx={{ maxWidth: '100%' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>소ID</TableCell>
                                    <TableCell align="right">날짜</TableCell>
                                    <TableCell align="right">이벤트명</TableCell>
                                    <TableCell align="right">내용</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {eventList.map((event: any) => {
                                    return (
                                        <TableRow
                                            key={event.id}>
                                            <TableCell align="right">{event.cowIdentifyId}</TableCell>
                                            <TableCell align="right">{event.date}</TableCell>
                                            <TableCell align="right">{event.eventName}</TableCell>
                                            <TableCell align="right">{event.description}</TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    style={{ backgroundColor: "#628CF7", marginRight: '10px' }}
                                                    onClick={() => EventClickEditButton(event)}>
                                                    수정
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    style={{ backgroundColor: "#f04141", marginRight: '10px' }}
                                                    onClick={() => deleteEvent(event.id)}>
                                                    삭제
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                )}
                            </TableBody>
                        </Table>
                    </Root>
                </TableContainer>
            </Box>
        }
        {/* 그래프 부분 */}
        <Box sx={{ mt: 3 }}>
            <Card>
                <CardHeader
                    title="센서 데이터 그래프"
                />
                <Divider />
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <CardHeader title="목걸이 센서 데이터" />
                            {nbattery && <Box>배터리 잔량: {nbattery}</Box>}
                            {nrssi && <Box>RF 수신 감도: {nrssi}</Box>}
                            {nsensorVer && <Box>센서 종류: {nsensorVer === "N" ? "목걸이 센서" : "오류"}</Box>}
                            {nmacAddr && <Box>MacAddr: {nmacAddr}</Box>}
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ height: 400, position: 'relative' }}>
                                {nSeries.length !== 0 && <NsensorChart series={nSeries} labels={nLabels} />}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid item xs={12}>
                            <CardHeader title="분만 센서 데이터" />
                            {vbattery && <Box>배터리 잔량: {vbattery}</Box>}
                            {vrssi && <Box>RF 수신 감도: {vrssi}</Box>}
                            {vsensorVer && <Box>센서 종류: {vsensorVer === "V" ? "분만 센서" : "오류"}</Box>}
                            {vmacAddr && <Box>MacAddr: {vmacAddr}</Box>}
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ height: 400, position: 'relative' }}>
                                {vSeries.length !== 0 && <VsensorChart series={vSeries} labels={vLabels} />}
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    </div >
};

export default CowDetail;