function CF_leftPad(value: number) {
    if (Number(value) >= 10) {
        return value;
    }
    return "0" + value;
}

export const changeDateToString = (targetDate: Date) => {
    const year = targetDate.getFullYear();
    const month = CF_leftPad(targetDate.getMonth() + 1);
    const day = CF_leftPad(targetDate.getDate());
    return [year, month, day].join('-');
}