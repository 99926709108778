import { useState, useEffect, useContext } from 'react';
import Table from '@mui/material/Table';
import { styled } from '@mui/system';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';
import { deleteByGroupId, getGroupsByFarmId, postGroup, putGroup } from '../axios';
import { Link } from 'react-router-dom';
import { FarmContext } from '../Context/Farm';
import TableSortLabel from '@mui/material/TableSortLabel';

interface IGroup {
    id: string,
    name: string,
    category: string
};

interface IValues {
    id: string,
    name: string,
    category: string,
};

interface IPostData {
    id: string,
    name: string,
    category: string,
    farmId: string,
};

const Root = styled('div')`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    text-align: left;
    padding: 8px;
    font-size: 15px;
    padding: 16px;
  }

  th {
    background-color: #F3F4F6;
    color: #374151;
    font-size: 15px;    
  }
`;

const Group = () => {
    const [groupList, setGroupList] = useState<Array<IGroup> | undefined>(undefined);
    const [updateGroupId, setUpdateGroupId] = useState<string>('');
    const [action, setAction] = useState<any>(undefined);
    const [open, setOpen] = useState<boolean>(false);
    const [values, setValues] = useState<IValues>({
        id: '',
        name: '',
        category: '',
    });
    const { selectFarmId } = useContext<IFarmContext>(FarmContext);

    const initFormData = () => {
        setValues({
            id: '',
            name: '',
            category: '',
        })
    };

    const openGroupForm = () => {
        setAction('create');
        setOpen(true);
        initFormData();
    };

    const closeGroupForm = () => setOpen(false);
    const getGroupFromServer = () => getGroupsByFarmId(selectFarmId as string).then(res => setGroupList(res.data));

    useEffect(() => {
        init()
    }, [selectFarmId]);

    const init = () => {
        if (selectFarmId) {
            getGroupFromServer();
            // const timer = setInterval(() => {
            //     getGroupFromServer();
            // }, 30000);
            // return () => clearTimeout(timer);
        }
    }

    const clickEditButton = (group: IGroup) => {
        setUpdateGroupId(group.id);
        setAction('update');
        setOpen(true)
        setValues({
            id: group.id,
            name: group.name,
            category: group.category,
        })
    };

    const clickDeleteButton = async (id: string) => {
        const res = await deleteByGroupId(id)
        if (res.data === 'Delete success') {
            getGroupFromServer();
        }
    };

    const createGroup = async (data: IPostData) => {
        try {
            const res = await postGroup(data)
            if (res.data === 'success') {
                getGroupFromServer();
                closeGroupForm();
            }
        } catch (e: any) {
            console.log('Error', e)
        }
    }

    const updateGroup = async (data: IPostData) => {
        try {
            const res = await putGroup(data, updateGroupId)
            if (res.data === 'Update success') {
                getGroupFromServer();
                closeGroupForm();
            }
        } catch (e: any) {
            console.log('Error', e)
        }
    }

    const handleChange = (prop: keyof IValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleSubmit = () => {
        if (action === 'create') {
            createGroup({ ...values, farmId: selectFarmId as string })
        } else if (action === 'update') {
            updateGroup({ ...values, farmId: selectFarmId as string })
        }
    };

    // 데이터 저장할 상태와 정렬 순서 저장할 상태 정의
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<keyof IGroup>('name');
    const sortData = (property: keyof IGroup) => {
        if (!groupList) return []; //  undefined인 경우 빈 배열 반환

        const isAscending = orderBy === property && order === 'asc';
        const sortedData = groupList.slice().sort((a, b) => {
            const valueA = typeof a[property] === 'string' ? isNaN(parseInt(a[property], 10))
                ? a[property] : parseInt(a[property], 10) : a[property];
            const valueB = typeof b[property] === 'string' ? isNaN(parseInt(b[property], 10))
                ? b[property] : parseInt(b[property], 10) : b[property];
            if (valueA < valueB) return isAscending ? -1 : 1;
            if (valueA > valueB) return isAscending ? 1 : -1;
            return 0;
        });
        setGroupList(sortedData);
        setOrder(isAscending ? 'desc' : 'asc');
        setOrderBy(property);
    }


    return <div>
        <Box sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            m: -1
        }}>
            <Typography sx={{ m: 1 }} variant="h5">
                그룹
            </Typography>
            <Box sx={{ m: 1 }}>
                <Button color="info"
                    variant="contained" onClick={openGroupForm}>
                    그룹관리 생성
                </Button>
            </Box>
        </Box>

        <Dialog open={open} onClose={closeGroupForm}>
            <DialogTitle>그룹 추가</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    추가할 그룹의 정보를 입력해주세요.
                </DialogContentText>
                <br />
                <TextField
                    autoFocus
                    margin="dense"
                    id="serial"
                    label="소 그룹 이름"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.name}
                    onChange={handleChange('name')}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="serial"
                    label="소 카테고리 이름"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.category}
                    onChange={handleChange('category')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeGroupForm()}>취소</Button>
                <Button onClick={handleSubmit}>추가</Button>
            </DialogActions>
        </Dialog>

        {groupList &&
            <Box sx={{ mt: 3 }}>
                <TableContainer component={Paper}>
                    <Root sx={{ maxWidth: '100%' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === 'name'}
                                            direction={order}
                                            onClick={() => sortData('name')}>
                                            이름
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === 'category'}
                                            direction={order}
                                            onClick={() => sortData('category')}>
                                            카테고리
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groupList.map((group: IGroup) => {
                                    return (
                                        <TableRow
                                            key={group.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="right">
                                                <Link
                                                    to={`/dashboard/groupDetail/${group.id}`}
                                                    style={{ textDecoration: 'none' }}>
                                                    {group.name}
                                                </Link>
                                            </TableCell>
                                            <TableCell align="right">{group.category}</TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    style={{ backgroundColor: "#628CF7", marginRight: '10px' }}
                                                    onClick={() => clickEditButton(group)}>
                                                    수정
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    style={{ backgroundColor: "#f04141", marginRight: '10px' }}
                                                    onClick={() => clickDeleteButton(group.id)}>
                                                    삭제
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                )}
                            </TableBody>
                        </Table>
                    </Root>
                </TableContainer>
            </Box>
        }
    </div>
};

export default Group;