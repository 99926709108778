
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Tabs, Tab } from '@mui/material';




const columns: GridColDef[] = [

    { field: 'identifyId', headerName: '개체 ID', width: 80 },
    { field: 'groupName', headerName: '그룹 명', width: 160 },
    { field: 'nsensorSerial', headerName: '목걸이 센서', width: 120 },
    { field: 'vsensorSerial', headerName: '분만 센서', width: 120, },
    { field: 'ageDay', headerName: '일령', width: 90, },
    { field: 'statusName', headerName: '상태', width: 160, },
    { field: 'emptyDays', headerName: '공태일', width: 90, },
    { field: 'birthCnt', headerName: '산차', width: 200, },
    { field: 'fertilization', headerName: '수정날짜', width: 200, },
    { field: 'cctv', headerName: 'CCTV', width: 110, },
];
interface IProps {
    visibleCowList: any
}

const EstrusTable = ({ visibleCowList }: IProps) => {

    return <div>

        <Box sx={{ height: '100%', width: '100%', bgcolor: 'background.paper' }}>


            <DataGrid
                rows={visibleCowList || []}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5]}
                autoHeight
            />
        </Box>
    </div>
};

export default EstrusTable;