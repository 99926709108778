import React, { useState, useEffect, useContext } from 'react';
import { DataGrid, GridColDef, GridValueGetterParams, GridRowModel } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { FarmContext } from '../../Context/Farm';
import { getCowsByFarmId, getEventByFarmId } from '../../axios';
import { GridCellParams } from '@material-ui/data-grid';



const columns: GridColDef[] = [
    {
        field: 'no',
        headerName: 'NO.',
        width: 60,
    },
    {
        field: 'cowIdentifyId',
        headerName: '개체ID',
        width: 100,
        renderCell: (params) => {
            const cowId = params.row.cowId as string;
            return <a href={`/dashboard/cowDetail/${cowId}`} style={{ textDecoration: 'none' }}>
                {params.value}
            </a>;
        }
    },

    { field: 'eventName', headerName: '이벤트 명', width: 130 },
    { field: 'description', headerName: '이벤트 상세', width: 300 },
    { field: 'date', headerName: '시간', width: 200 },
    { field: 'groupName', headerName: '그룹', width: 150, },
    // { field: 'vod', headerName: 'VOD', width: 100, },
    { field: 'cctv', headerName: 'CCTV', width: 100, },
];


const CowTable = () => {
    const [eventList, setEventList] = useState<Array<IEvent> | undefined>(undefined);
    const { selectFarmId } = useContext<IFarmContext>(FarmContext);
    // const [cowList, setCowList] = useState<Array<ICow> | undefined>(undefined);


    useEffect(() => {
        init()
    }, [selectFarmId]);

    const init = () => {
        if (selectFarmId) {
            getEventFromServer();
            // getCowsByFarmId(selectFarmId).then((res) => {
            //     setCowList(res.data)
            // });
        }
    }

    const getEventFromServer = () => selectFarmId ? getEventByFarmId(selectFarmId as string).then(res => { setEventList(res.data); }) : alert('농장을 선택해주세요');
    return (
        <Box sx={{ height: 400, width: '100%', bgcolor: 'background.paper' }}>


            <DataGrid
                rows={eventList || []}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
            />
        </Box>
    );
}

export default CowTable;