import { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { FarmContext } from '../../Context/Farm';
import { Box, Drawer, Avatar, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Logo } from './logo';
import { MHidden } from '../../components/@material-extend';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { NavItem } from './nav-item';
import { getCowsByFarmId } from '../../axios';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';



const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: DRAWER_WIDTH
  }
}));

interface IProps {
  isOpenSidebar: boolean,
  onCloseSidebar: Function
}

const DashboardSidebar = ({ isOpenSidebar, onCloseSidebar }: IProps) => {
  const { pathname } = useLocation();
  const { selectFarmId } = useContext<IFarmContext>(FarmContext);
  const [cowList, setCowList] = useState<Array<ICow> | []>([]);
  const [dashboardValue, setDashboardValue] = useState<any>({ estrus: 0, waitngPreDecision: 0, childbirthSoon: 0, focusManagement: 0 });

  useEffect(() => {
    if (isOpenSidebar || selectFarmId) {
      onCloseSidebar();
      getCowFromServer();
    }
  }, [pathname, selectFarmId]);
  const getCowFromServer = () => getCowsByFarmId(selectFarmId as string).then(res => {
    setCowList(res.data)
    const estrus = res.data.filter((cow: ICow) => cow.statusName === '발정중').length;
    const waitngPreDecision = res.data.filter((cow: ICow) => cow.statusName === '임신판정검사대기').length;
    const childbirthSoon = res.data.filter((cow: ICow) => cow.statusName === '분만임박').length;
    const focusManagement = res.data.filter((cow: ICow) => cow.statusName === '집중관리').length;
    //json
    //{키 : 값}
    // 앞에 readyPre 키 (초기 키 readyPre)
    // 뒤에 readyPre 위 변수 값  (초기 값 0)
    setDashboardValue({
      estrus: estrus,
      waitngPreDecision: waitngPreDecision,
      childbirthSoon: childbirthSoon,
      focusManagement: focusManagement
    })
  });


  const items = [
    { href: '/dashboard', title: '홈' },
    { href: '/dashboard/event', title: '이벤트 관리' },
    { href: '/dashboard/cow', title: '개체 관리' },
    { href: '/dashboard/group', title: '그룹관리' },
    { href: '/dashboard/vsensor', title: '분만 센서' },
    { href: '/dashboard/nsensor', title: '목걸이 센서' },
    { href: '/dashboard/sensorStatus', title: '센서상태' },
    { href: '/dashboard/cowStatus', title: '소상태' },
    { href: '/dashboard/contact', title: '연락처' },
    // {href: '/login',title: 'Login'},
    // { href: '/register',title: 'Register'},
    { href: '/404', title: 'CCTV' },
    // { href: '/404', title: '보고서관리' }
  ];

  const content = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} >
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Link style={{ textDecorationLine: 'none' }} to="/">
          <Logo sx={{ height: 42, width: 42, }} />
        </Link>
      </Box>

      <Box sx={{ px: 2, color: '##e6e7eb', fontWeight: 'bold', fontSize: '15px', marginY: 2 }}>
        <Link to='/dashboard/cow' style={{ textDecoration: "none", color: "white" }}>
          <Box sx={{ alignItems: 'center', backgroundColor: '#485b82', display: 'flex', justifyContent: 'space-between', alignContent: 'center', px: 3, py: '11px', borderRadius: 3, marginY: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 16 }}> 전체 </Box>
            <Box sx={{ backgroundColor: '#F5F6F7', width: '42px', height: '42px', borderRadius: '30px' }}><Box sx={{ paddingTop: 1.5, textAlign: 'center', color: "#6e95e6" }}>{cowList.length}</Box></Box>
          </Box>
        </Link>
        <Link to='/dashboard/statusChart' style={{ textDecoration: "none", color: "white" }}>
          <Box sx={{ alignItems: 'center', backgroundColor: '#485b82', cursor: 'pointer', display: 'flex', justifyContent: 'space-between', px: 3, py: '11px', borderRadius: 3, marginY: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 16 }}><LeaderboardIcon sx={{ fontSize: 24, paddingTop: -1, marginRight: 1 }}></LeaderboardIcon> 상태별 개체 현황</Box>
          </Box>
        </Link>
        <Link to='/dashboard/contact' style={{ textDecoration: "none", color: "white" }}>
          <Box sx={{ alignItems: 'center', backgroundColor: '#485b82', cursor: 'pointer', display: 'flex', justifyContent: 'space-between', px: 3, py: '11px', borderRadius: 3, marginY: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 16 }}><EditNotificationsIcon sx={{ fontSize: 24, paddingTop: -1, marginRight: 1 }}></EditNotificationsIcon> 알람 연락처 관리 </Box>
          </Box>
        </Link>
      </Box>

      <Box>
        <Box sx={{ alignItems: 'center', cursor: 'pointer', display: 'flex', px: 3, borderRadius: 3, marginY: 1, fontWeight: 'bold', marginTop: 4 }}>
          <Box sx={{ margin: 1 }}>보고서</Box>
          <ContentPasteIcon />
        </Box>

        <Grid container sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', color: '#6B7A99', fontSize: '14px' }}>
          <Grid item xs={5} sx={{ backgroundColor: 'white', borderRadius: 2, margin: 1, padding: 2 }}>
            <Avatar sx={{ backgroundColor: '#FA6EA9', marginX: 'auto', fontSize: '18px' }}>{dashboardValue.estrus}</Avatar>
            <Box sx={{ marginY: 1, fontWeight: 'bold' }}>발정중</Box>
          </Grid>
          <Grid item xs={5} sx={{ backgroundColor: 'white', borderRadius: 2, margin: 1, padding: 2 }}>
            <Avatar sx={{ backgroundColor: '#628CF7', marginX: 'auto', fontSize: '18px' }}>{dashboardValue.waitngPreDecision}</Avatar>
            <Box sx={{ marginY: 1, fontWeight: 'bold' }}>임신판정대기</Box>
          </Grid>
          <Grid item xs={5} sx={{ backgroundColor: 'white', borderRadius: 2, margin: 1, padding: 2 }}>
            <Avatar sx={{ backgroundColor: '#F6B50D', marginX: 'auto', fontSize: '18px' }}>{dashboardValue.childbirthSoon}</Avatar>
            <Box sx={{ marginY: 1, fontWeight: 'bold' }}>분만임박</Box>
          </Grid>
          <Grid item xs={5} sx={{ backgroundColor: 'white', borderRadius: 2, margin: 1, padding: 2 }}>
            <Avatar sx={{ backgroundColor: '#46BC7D', marginX: 'auto', fontSize: '18px' }}>{dashboardValue.focusManagement}</Avatar>
            <Box sx={{ marginY: 1, fontWeight: 'bold' }}>집중관리</Box>
          </Grid>
        </Grid>

      </Box>


    </Box>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer open={isOpenSidebar} onClose={() => onCloseSidebar()} PaperProps={{ sx: { width: DRAWER_WIDTH, backgroundColor: '#131314', color: '#FFFFFF' } }}>
          <Box sx={{ p: 3, textAlign: 'center' }}>
            <Link style={{ textDecorationLine: 'none' }} to="/">
              <Logo sx={{ height: 42, width: 42, }} />
            </Link>
          </Box>
          {items.map((item) => (
            <NavItem
              key={item.title}
              href={item.href}
              title={item.title}
            />
          ))}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer open variant="persistent" PaperProps={{ sx: { width: DRAWER_WIDTH, backgroundColor: '#131314', color: '#FFFFFF' } }}>
          {content}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
};

export default DashboardSidebar;