import React, { createContext, useState, useEffect } from 'react';
import { getSelectedFarmId, setSelectedFarmId } from '../../axios/Farm';

const farmContext: IFarmContext = {
    isLoading: false,
    selectFarmId: undefined,
    handleSelectFarmId: () => { },
};

const FarmContext = createContext(farmContext);

interface Props {
    children: JSX.Element | Array<JSX.Element>;
}

const FarmContextProvider = ({ children }: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectFarmId, setSelectFarmId] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (getSelectedFarmId()) {
            setSelectFarmId(getSelectedFarmId())
        }
    }, []);

    const handleSelectFarmId = (farmId: string) => {
        setSelectFarmId(farmId);
        setSelectedFarmId(farmId);
    }

    return (
        <FarmContext.Provider
            value={{
                isLoading,
                selectFarmId,
                handleSelectFarmId
            }}>
            {children}
        </FarmContext.Provider>
    );
};
export { FarmContextProvider, FarmContext };