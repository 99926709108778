import { useState, useEffect, useContext } from 'react';
import Table from '@mui/material/Table';
import { styled } from '@mui/system';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import { deleteEventById, postEvent, putEvent, getCowsByFarmId, getEventByFarmId } from '../axios';
import { Link } from 'react-router-dom';
import {
    Box, Typography, Card,
    CardContent, InputAdornment,
    SvgIcon,
} from '@mui/material';
import { Search as SearchIcon } from '../icons/search';
import { FarmContext } from '../Context/Farm';
import TableSortLabel from '@mui/material/TableSortLabel';

interface ICow {
    id: string,
    identifyId: string,
    ageDay: string
};

interface IEvent {
    id: string,
    cowId: string,
    date: string,
    eventName: string,
    description: string,
    cowIdentifyId: string,
    ageDay: string
};

interface IValues {
    id: string,
    eventName: string,
    date: string,
    description: string,
};

interface IPostData {
    id: string,
    cowId: string,
    date: string,
    eventName: string,
    description: string,
    cowIdentifyId: string,
    ageDay: string,
    farmId: string,
};

const Root = styled('div')`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    background-color: #F3F4F6;
    color: #374151;
  }
`;

const Event = () => {
    const [action, setAction] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [eventList, setEventList] = useState<Array<IEvent> | undefined>(undefined);
    const [visibleEventList, setVisibleEventList] = useState<Array<IEvent> | undefined>(undefined);
    const [cow, setCow] = useState<string>('');
    const [cowList, setCowList] = useState<Array<ICow> | undefined>(undefined);
    const [updateEventId, setUpdateEventId] = useState<string>('');
    const [values, setValues] = useState<IValues>({
        id: '',
        eventName: '',
        date: '',
        description: ''
    });
    const [searchText, setSearchText] = useState<string>('');
    const { selectFarmId } = useContext<IFarmContext>(FarmContext);
    // 데이터 저장할 상태와 정렬 순서 저장할 상태 정의
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<keyof IEvent>('date');


    useEffect(() => {
        init()
    }, [selectFarmId]);

    const init = () => {
        if (selectFarmId) {
            getEventFromServer();
            getCowsByFarmId(selectFarmId).then((res) => {
                setCowList(res.data)
            });
        }
        // const timer = setInterval(() => {
        //     getEventFromServer();
        // }, 30000);
        // return () => clearTimeout(timer);
    }

    const getEventFromServer = () => selectFarmId ? getEventByFarmId(selectFarmId as string).then(res => { setEventList(res.data); setVisibleEventList(res.data) }) : alert('농장을 선택해주세요');
    const deleteEvent = async (id: string) => {
        const res = await deleteEventById(id)
        if (res.data === 'Delete success') {
            getEventFromServer();
        }
    };

    const initFormData = () => {
        setValues({
            id: '',
            eventName: '',
            date: '',
            description: ''
        })
    };

    const openEventForm = () => {
        setAction('create');
        setOpen(true);
        initFormData();
    };

    const closeEventForm = () => setOpen(false);

    const handleChange = (prop: keyof IValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const createEvent = async (data: IPostData) => {
        try {
            const res = await postEvent(data)
            if (res.data === 'success') {
                getEventFromServer();
                closeEventForm();
            }
        } catch (e: any) {
            console.log('Error', e)
        }
    };

    const updateEvent = async (data: IPostData) => {
        try {
            const res = await putEvent(data, updateEventId)
            if (res.data === 'Update success') {
                getEventFromServer();
                closeEventForm();
            }
        } catch (e: any) {
            console.log('Error', e)
        }
    };

    const clickEditButton = (event: IEvent) => {
        setUpdateEventId(event.id);
        setCow(event.cowId);
        setAction('update');
        setOpen(true)
        setValues({
            id: event.id,
            eventName: event.eventName,
            date: event.date,
            description: event.description
        })
    };

    const handleSubmit = () => {
        if (action === 'create') {
            createEvent({ ...values, cowId: cow, cowIdentifyId: cow, ageDay: cow, farmId: selectFarmId as string })
        } else if (action === 'update') {
            updateEvent({ ...values, cowId: cow, cowIdentifyId: cow, ageDay: cow, farmId: selectFarmId as string })
        }
    };

    const changeSearchText = (e: any) => {
        setSearchText(e.target.value);
        const filterData = eventList?.filter((event: IEvent) =>
            event.cowIdentifyId.includes(e.target.value) ||
            event.date.includes(e.target.value) ||
            event.eventName.includes(e.target.value) ||
            event.description.includes(e.target.value)
        )
        setVisibleEventList(filterData);
    }

    // 데이터 정렬 함수 
    const sortData = (property: keyof IEvent) => {
        if (!visibleEventList) return []; //  undefined인 경우 빈 배열 반환

        const isAscending = orderBy === property && order === 'asc';
        const sortedData = visibleEventList.slice().sort((a, b) => {
            const valueA = typeof a[property] === 'string' ? isNaN(parseInt(a[property], 10))
                ? a[property] : parseInt(a[property], 10) : a[property];
            const valueB = typeof b[property] === 'string' ? isNaN(parseInt(b[property], 10))
                ? b[property] : parseInt(b[property], 10) : b[property];
            if (valueA < valueB) return isAscending ? -1 : 1;
            if (valueA > valueB) return isAscending ? 1 : -1;
            return 0;
        });
        setVisibleEventList(sortedData);
        setOrder(isAscending ? 'desc' : 'asc');
        setOrderBy(property);
    }

    return <div>
        <Box sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            m: -1
        }}>
            <Typography sx={{ m: 1 }} variant="h5">
                이벤트
            </Typography>
            <Box sx={{ m: 1 }}>
                <Button variant="contained" onClick={openEventForm}>
                    이벤트 생성
                </Button>
            </Box>
        </Box>

        <Box sx={{ mt: 3 }}>
            <Card>
                <CardContent>
                    <Box sx={{ maxWidth: 500 }}>
                        <TextField
                            fullWidth
                            value={searchText}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon
                                            color="action"
                                            fontSize="small"
                                        >
                                            <SearchIcon />
                                        </SvgIcon>
                                    </InputAdornment>
                                )
                            }}
                            onChange={changeSearchText}
                            placeholder="이벤트 검색"
                            variant="outlined"
                        />
                    </Box>
                </CardContent>
            </Card>
        </Box>

        <Dialog open={open} onClose={closeEventForm}>
            <DialogTitle>이벤트 추가</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    추가할 이벤트의 정보를 입력해주세요.
                </DialogContentText>
                <br />
                <TextField
                    id="outlined-select-group"
                    margin="dense"
                    select
                    label="소이름"
                    value={cow}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCow(event.target.value)}
                    fullWidth
                >
                    {cowList && cowList.map((cow: any) => (
                        <MenuItem key={cow.id} value={cow.id}>
                            {cow.identifyId}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    autoFocus
                    margin="dense"
                    id="date"
                    label="날짜"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.date}
                    onChange={handleChange('date')}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="eventName"
                    label="이벤트이름"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.eventName}
                    onChange={handleChange('eventName')}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="description"
                    label="설명"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.description}
                    onChange={handleChange('description')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeEventForm()}>취소</Button>
                <Button onClick={handleSubmit}>추가</Button>
            </DialogActions>
        </Dialog>
        {visibleEventList &&
            <Box sx={{ mt: 3 }}>
                <TableContainer component={Paper}>
                    <Root sx={{ maxWidth: '100%', maxHeight: '100%', height: '80vh' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ padding: { xs: 0, md: '16px' }, fontSize: { xs: '14px' } }}>
                                        <TableSortLabel
                                            active={orderBy === 'cowIdentifyId'}
                                            direction={order}
                                            onClick={() => sortData('cowIdentifyId')}>
                                            소ID
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center" sx={{ padding: { xs: 0, md: '16px' }, fontSize: { xs: '14px' } }}>
                                        <TableSortLabel
                                            active={orderBy === 'date'}
                                            direction={order}
                                            onClick={() => sortData('date')}>
                                            날짜
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center" sx={{ padding: { xs: 0, md: '16px' }, fontSize: { xs: '14px' } }}>
                                        <TableSortLabel
                                            active={orderBy === 'eventName'}
                                            direction={order}
                                            onClick={() => sortData('eventName')}>
                                            이벤트명
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center" sx={{ padding: { xs: 0, md: '16px' }, fontSize: { xs: '14px' } }}>
                                        <TableSortLabel
                                            active={orderBy === 'description'}
                                            direction={order}
                                            onClick={() => sortData('description')}>
                                            이벤트설명
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="center" sx={{ padding: { xs: 0, md: '16px' }, fontSize: { xs: '14px' } }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {visibleEventList.map((event: IEvent) => {
                                    return (
                                        <TableRow
                                            key={event.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center" sx={{ padding: { xs: '0px', md: '16px' }, width: { xs: '40px', sm: '100px' }, fontSize: { xs: '13px', sm: '14px' } }}>
                                                <Link
                                                    to={`/dashboard/cowDetail/${event.cowId}`}
                                                    style={{ textDecoration: 'none' }}>
                                                    {event.cowIdentifyId}
                                                </Link>
                                            </TableCell>
                                            <TableCell align="center" sx={{ padding: { xs: '0px', md: '16px' }, width: '100px', fontSize: { xs: '13px', sm: '14px' } }}>{event.date}</TableCell>
                                            <TableCell align="center" sx={{ padding: { xs: '0px', md: '16px' }, width: '100px', fontSize: { xs: '13px', sm: '14px' } }}>{event.eventName}</TableCell>
                                            <TableCell align="center" sx={{ padding: { xs: '0px', md: '16px' }, width: '100px', fontSize: { xs: '13px', sm: '14px' } }}>{event.description}</TableCell>
                                            <TableCell align="center" sx={{ padding: { xs: '0px', md: '16px' }, width: '100px', fontSize: { xs: '13px', sm: '14px' } }}>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    style={{ backgroundColor: "#628CF7", marginRight: '10px' }}
                                                    sx={{ marginBottom: { xs: '3px', md: 0 }, padding: { xs: 0, md: '4px' }, marginRight: { xs: '0px', md: '10px' } }}
                                                    onClick={() => clickEditButton(event)}>
                                                    수정
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    style={{ backgroundColor: "#f04141", marginRight: '10px' }}
                                                    sx={{ margin: { xs: '3px', md: 0 }, padding: { xs: 0, md: '4px' } }}
                                                    onClick={() => deleteEvent(event.id)}>
                                                    삭제
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                )}
                            </TableBody>
                        </Table>
                    </Root>
                </TableContainer>
            </Box>
        }
    </div >
};
export default Event;