import Router from './routes';

import './App.css';
import { FarmContextProvider } from './Context/Farm';

function App() {
  return (
    <FarmContextProvider>
      <Router />
    </FarmContextProvider>
  )
}

export default App;


// import React from 'react';
// import { VideoView } from './server';

// const App: React.FC = () => {
//   return (
//     <div>
//       <h1>RTSP Streaming</h1>
//       <VideoView />
//     </div>
//   );
// };

// export default App;


// import React from 'react';
// import RTSPVideo from './server';
// import JSMpegPlayer from 'jsmpeg-player';

// const App: React.FC = () => {
//   return (
//     <div>
//       <h1>RTSP Video Stream</h1>
//       <RTSPVideo />
//     </div>
//   );
// };

// export default App;
