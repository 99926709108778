import { useState, useEffect, useContext } from 'react';
import Table from '@mui/material/Table';
import { styled } from '@mui/system';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Box, CardContent, CardHeader, Card, Divider, Typography, Grid } from '@mui/material';
import { getNsensorById, deleteNsensorById, putNsensor, getSensorStatus, getNsensorDataById, getNsensorDataByIdAndDate } from '../axios';
import { useLocation, useNavigate } from 'react-router-dom';
import NsensorChart from '../components/NsensorChart';
import { FarmContext } from '../Context/Farm';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

interface ISensorStatus {
    id: string,
    name: string
};
interface IValues {
    serial: string,
    daysInReport: string,
    detail: string,
    sensorId: string
};
interface INsensor {
    id: string,
    serial: string,
    sensorStatusId: string,
    status: string,
    daysInReport: string,
    alarmActive: string,
    sensorId: string,
    cowId: string
};
interface IPostData {
    serial: string,
    sensorStatusId: string,
    daysInReport: string,
    detail: string,
    alarmActive: string,
    sensorId: string,
    farmId: string,
};
interface ISeries {
    name: string;
    type: string;
    data: Array<number>;
}

const Root = styled('div')`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    text-align: left;
    padding: 8px;
    font-size: 15px;
    padding: 16px;
  }

  th {
    background-color: #F3F4F6;
    color: #374151;
    font-size: 15px;    
  }
`;

const NsensorDetail = () => {
    const { pathname } = useLocation();
    let navigate = useNavigate();
    const nsensorId = pathname.split('/')[3];
    const [series, setSeries] = useState<Array<ISeries>>([]);
    const [labels, setlabels] = useState<Array<string>>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [nsensor, setNsensor] = useState<INsensor | undefined>(undefined);
    const [sensorStatusList, setSensorStatusList] = useState<Array<ISensorStatus> | undefined>(undefined);
    const [sensorStatus, setSensorStatus] = useState<string>('');
    const [active, setActive] = useState<boolean>(true);
    const [values, setValues] = useState<IValues>({
        serial: '',
        daysInReport: '',
        detail: '',
        sensorId: ''
    });
    const [battery, setBattery] = useState<string | undefined>(undefined);
    const [rssi, setRssi] = useState<string | undefined>(undefined);
    const [sensorVer, setSensorVer] = useState<string | undefined>(undefined);
    const [macAddr, setMacAddr] = useState<string | undefined>(undefined);
    const { selectFarmId } = useContext<IFarmContext>(FarmContext);

    const [startDateNsensor, setStartDateNsensor] = useState<Dayjs | null>(null);
    const [endDateNsensor, setEndDateNsensor] = useState<Dayjs | null>(null);

    const [sensorData, setSensorData] = useState<any>();

    useEffect(() => {
        init()
    }, []);
    const init = () => {
        if (selectFarmId) {
            getNsensorFromServer();
            getSensorStatus().then((res) => {
                setSensorStatusList(res.data)
            })
            sensorDataUpdate();
            // const timer = setInterval(() => {
            //     sensorDataUpdate();
            //     getNsensorFromServer();
            // }, 30000);
            // return () => clearTimeout(timer);
        }
    }
    const sensorDataUpdate = () => {
        getNsensorDataById(nsensorId).then((res) => {
            setSensorData(res.data);
            setSeries([
                {
                    name: 'temp',
                    type: 'line',
                    data: res.data.map((data: any) => Number(data.temp))
                },
                {
                    name: 'hPa',
                    type: 'line',
                    data: res.data.map((data: any) => Number(data.hPa)),
                },
                {
                    name: 'vector',
                    type: 'area',
                    data: res.data.map((data: any) => Number(data.vector))
                }
            ]);
            setlabels(
                res.data.map((data: any) => data.krTime)
                // res.data.map((data: any) => {
                //     var arr = data.time.split(/-|\s|:/);// split string and create array.
                //     const date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]); // decrease month value by 1
                //     console.log('date', new Date(data.time.split(' ')[0]));
                //     return new Date(data.time.split(' ')[0]).getTime();
                // })
            );
            setBattery(res.data[0].battery);
            setRssi(res.data[0].rssi);
            setSensorVer(res.data[0].sensor_ver);
            setMacAddr(res.data[0].macAddr);
        })
    }
    const closeNsensorForm = () => setOpen(false)
    const handleChange = (prop: keyof IValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    }
    const clickEditButton = (nsensor: INsensor) => {
        setSensorStatus(nsensor.sensorStatusId)
        setOpen(true)
        setValues({
            serial: nsensor.serial,
            daysInReport: nsensor.daysInReport,
            detail: '',
            sensorId: nsensor.sensorId
        })
    }
    const handleSubmit = () => {
        updateNsensor({ ...values, alarmActive: active ? '1' : '0', sensorStatusId: sensorStatus, farmId: selectFarmId as string })
    };
    const getNsensorFromServer = () => getNsensorById(nsensorId).then(res => setNsensor(res.data))
    const updateNsensor = async (data: IPostData) => {
        try {
            const res = await putNsensor(data, nsensorId)
            if (res.data === 'Update success') {
                getNsensorFromServer();
                closeNsensorForm();
            }
        } catch (e: any) {
            console.log('Error', e)
        }
    }
    const deleteNsensor = async (id: string) => {
        const res = await deleteNsensorById(id)
        if (res.data === 'Delete success') {
            getNsensorFromServer();
            navigate('/dashboard/nsensor');
        }
    }

    const clickSearchNSensorData = () => {
        if (!nsensorId) return;
        if (!startDateNsensor) return;
        if (!endDateNsensor) return;

        if (endDateNsensor.diff(startDateNsensor, "day") > 7) {
            return alert("7일 이상 조회가 불가능합니다.")
        }
        else if (endDateNsensor.diff(startDateNsensor, "day") < 0) {
            return alert("날짜를 확인해 주세요.")
        }

        getNsensorDataByIdAndDate(nsensorId, startDateNsensor?.
            format('YYYY-MM-DD HH:mm:ss'), endDateNsensor?.format('YYYY-MM-DD HH:mm:ss'))
            .then(res => {
                setSensorData(res.data);
                setSeries([
                    {
                        name: 'temp',
                        type: 'line',
                        data: res.data.map((data: any) => Number(data.temp))
                    },
                    {
                        name: 'hPa',
                        type: 'line',
                        data: res.data.map((data: any) => Number(data.hPa))
                    },
                    {
                        name: 'vector',
                        type: 'area',
                        data: res.data.map((data: any) => Number(data.vector))
                    }
                ]);
                setlabels(
                    res.data.map((data: any) => data.krTime)
                );
                setBattery(res.data[0].battery);
                setRssi(res.data[0].rssi);
                setSensorVer(res.data[0].sensor_ver);
                setMacAddr(res.data[0].macAddr);
            })
            .catch(err => console.log('err', err));

    }

    return <div>
        <Box sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            m: -1
        }}>
            <Typography sx={{ m: 1 }} variant="h5">
                목걸이 센서 상세
            </Typography>
            <Box sx={{ m: 1 }}>
            </Box>
        </Box>

        <Dialog open={open} onClose={closeNsensorForm}>
            <DialogTitle>목걸이 센서 추가</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    목걸이 센서 정보를 입력해주세요.
                </DialogContentText>
                <br />
                <TextField
                    autoFocus
                    margin="dense"
                    id="serial"
                    label="시리얼 넘버"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.serial}
                    onChange={handleChange('serial')}
                />
                <TextField
                    id="outlined-select-status"
                    margin="dense"
                    select
                    label="상태"
                    value={sensorStatus}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSensorStatus(event.target.value)}
                    fullWidth
                >
                    {sensorStatusList && sensorStatusList.map((sensorStatus: ISensorStatus) => (
                        <MenuItem key={sensorStatus.id} value={sensorStatus.id}>
                            {sensorStatus.name}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    autoFocus
                    margin="dense"
                    id="daysInReport"
                    label="Days In Report"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.daysInReport}
                    onChange={handleChange('daysInReport')}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="sensorId"
                    label="센서 ID"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.sensorId}
                    onChange={handleChange('sensorId')}
                />
                <br />
                <FormControlLabel
                    control={
                        <Switch checked={active} onChange={() => setActive(!active)} name="alarmActive" />
                    }
                    label="알람 활성화"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeNsensorForm()}>취소</Button>
                <Button onClick={handleSubmit}>추가</Button>
            </DialogActions>
        </Dialog>

        {nsensor &&
            <Box sx={{ mt: 3 }}>
                <TableContainer component={Paper}>
                    <Root sx={{ maxWidth: '100%' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>시리얼번호</TableCell>
                                    <TableCell align="right">상태</TableCell>
                                    <TableCell align="right">Days In Report</TableCell>
                                    <TableCell align="right">알람상태</TableCell>
                                    <TableCell align="right">센서ID</TableCell>
                                    <TableCell align="right">소ID</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    key={nsensor.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>{nsensor.serial}</TableCell>
                                    <TableCell align="right">{nsensor.status}</TableCell>
                                    <TableCell align="right">{nsensor.daysInReport}</TableCell>
                                    <TableCell align="right">{nsensor.alarmActive}</TableCell>
                                    <TableCell align="right">{nsensor.sensorId}</TableCell>
                                    <TableCell align="right">{nsensor.cowId}</TableCell>
                                    <TableCell align="right">
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            style={{ backgroundColor: "#628CF7", marginRight: '10px' }}
                                            onClick={() => clickEditButton(nsensor)}>
                                            수정
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            style={{ backgroundColor: "#f04141", marginRight: '10px' }}
                                            onClick={() => deleteNsensor(nsensor.id)}>
                                            삭제
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Root>
                </TableContainer>
            </Box>
        }
        <Box sx={{ mt: 3 }}>
            <Card>
                <CardHeader
                    title="목걸이 센서 데이터"
                />
                <Divider />
                <CardContent>
                    {/* 과거 데이터 조회 */}
                    <Grid container>
                        <Box sx={{ width: '300px' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateTimePicker']}>
                                    <DateTimePicker
                                        label="시작 날짜"
                                        value={startDateNsensor}
                                        onChange={(newValue) => setStartDateNsensor(newValue)}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                        <Box sx={{ width: '300px', marginLeft: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateTimePicker']}>
                                    <DateTimePicker
                                        label="종료 날짜"
                                        value={endDateNsensor}
                                        onChange={(newValue) => setEndDateNsensor(newValue)} />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                        <Box sx={{ marginTop: 2, marginLeft: 2 }}>
                            <Button variant="contained" onClick={clickSearchNSensorData}>조회</Button>

                        </Box>
                    </Grid>
                    {battery && <Box>배터리 잔량: {battery}</Box>}
                    {rssi && <Box>RF 수신 감도: {rssi}</Box>}
                    {sensorVer && <Box>센서 종류: {sensorVer === "N" ? "목걸이 센서" : "오류"}</Box>}
                    {macAddr && <Box>MacAddr: {macAddr}</Box>}
                    {series.length !== 0 && <>
                        <NsensorChart series={series} labels={labels} />
                        {sensorData && <>

                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={sensorData.map((data: any, index: number) => ({ ...data, id: index }))}
                                    columns={[
                                        { field: "battery", headerName: "battery" },
                                        { field: "hPa", headerName: "hPa" },
                                        { field: "krTime", headerName: "krTime" },
                                        { field: "macAddr", headerName: "macAddr" },
                                        { field: "rssi", headerName: "rssi" },
                                        { field: "sensorID", headerName: "sensorID" },
                                        { field: "sensor_ver", headerName: "sensor_ver" },
                                        { field: "temp", headerName: "temp" },
                                        { field: "time", headerName: "time" },
                                        { field: "vector", headerName: "vector" }]}
                                    pageSize={100}
                                    checkboxSelection
                                />
                            </div>
                        </>}
                    </>}
                </CardContent>
            </Card>
        </Box>
    </div >
};
export default NsensorDetail;