import { Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

import Dashboard from './pages/Dashboard';
import Event from './pages/Event';
import Vsensor from './pages/Vsensor';
import Nsensor from './pages/Nsensor';
import NotFound from './pages/Page404';
import Cows from './pages/Cows'
import CowDetail from './pages/CowDetail';
import VsensorDetail from './pages/VsensorDetail';
import NsensorDetail from './pages/NsensorDetail';
import Group from './pages/Group';
import GroupDetail from './pages/GroupDetail';
import CowStatus from './pages/CowStatus';
import SensorStatus from './pages/SensorStatus';
import Contact from './pages/Contact';
import DashboardPregenancy from './pages/DashboardPregnancy';
import DashboardCalfs from './pages/DashboardCalfs';
import DashboardEstrus from './pages/DashboardEstrus';
import DashboardFertilization from './pages/DashboardFertilization';
import StatusChart from './pages/StatusChart';



export interface IRoute {
    path?: string;
    element?: React.ReactNode | null;
    children?: React.ReactNode;
}

const Routes = () => {
    return useRoutes([
        {
            path: '/dashboard',
            element: <DashboardLayout />,
            children: [
                { path: '', element: <Dashboard /> },
                { path: 'event', element: <Event /> },
                { path: 'vsensor', element: <Vsensor /> },
                { path: 'nsensor', element: <Nsensor /> },
                { path: 'cow', element: <Cows /> },
                { path: 'group', element: <Group /> },
                { path: 'groupDetail/:groupId', element: <GroupDetail /> },
                { path: 'cowDetail/:cowId', element: <CowDetail /> },
                { path: 'vsensorDetail/:vsensorId', element: <VsensorDetail /> },
                { path: 'nsensorDetail/:nsensorId', element: <NsensorDetail /> },
                { path: 'cowStatus', element: <CowStatus /> },
                { path: 'sensorStatus', element: <SensorStatus /> },
                { path: 'contact', element: <Contact /> },
                { path: 'calf', element: <DashboardCalfs /> },
                { path: 'pregnancy', element: <DashboardPregenancy /> },
                { path: 'fertilization', element: <DashboardFertilization /> },
                { path: 'estrus', element: <DashboardEstrus /> },
                { path: 'statusChart', element: <StatusChart /> },
                // { path: 'groupChart', element: <GroupChart /> },
            ]
        },
        {
            path: '/',
            element: <LogoOnlyLayout />,
            children: [
                { path: '404', element: <NotFound /> },
                { path: '/', element: <Navigate to="/dashboard" /> },
                { path: '*', element: <Navigate to="/404" /> }
            ]
        },
        { path: '*', element: <Navigate to="/404" replace /> }
    ])
}

export default Routes;

