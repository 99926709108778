// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

interface Isx {
  sx?: any;
}

export default function Logo({ sx }: Isx) {
  return <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />;
}
