import Chart from 'react-apexcharts';
interface ISeries {
    name: string;
    type: string;
    data: Array<number>;
}
interface IProps {
    series: Array<ISeries>;
    labels: Array<string>;
}
const NsensorChart = ({ series, labels }: IProps) => {
    return <div>
        {/* @ts-ignore */}
        <Chart options={{
            chart: {
                stacked: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [3, 3, 2],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%'
                }
            },
            colors: ['#FF5252', '#FFA21D', '#4680FF'],
            fill: {
                opacity: [1, 0.85, 0.25],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: 'vertical',
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                }
            },
            labels: labels,
            markers: {
                size: 0
            },
            xaxis: {
                labels: {
                    datetimeUTC: false,
                    datetimeFormatter: {
                        year: 'yyyy년 M월',
                        month: 'yyyy년 M월',
                        day: 'MM월 dd일',
                        hour: 'dd일 HH:mm',
                        minute: 'dd일 HH:mm:ss'
                    }
                },
                type: 'datetime'
            },
            yaxis: [{
                title: {
                    text: 'temp',
                },
            }, {
                opposite: true,
                title: {
                    text: 'vector'
                }
            }],
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: (y: any) => {
                        if (typeof y !== 'undefined') {
                            return y
                        }
                        return y;
                    }
                },
                x: {
                    format: 'yy년 M월 d일 HH시 mm분'

                }
            },
            legend: {
                labels: {
                    useSeriesColors: true
                },
                markers: {
                    // customHTML: [() => '', () => '', () => '']
                }
            }
        }} series={series}
            type="line"
            height={350}
        />
    </div>
}
export default NsensorChart;
