import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { Box, Button, ListItem } from '@mui/material';

interface IProps {
  href: string,
  title: string
}

export const NavItem = (props: IProps) => {
  const { href, title, ...others } = props;
  const location = useLocation();
  const active = href ? (location.pathname === href) : false;

  return (
    <ListItem disableGutters
      sx={{ justifyContent: 'center', paddingX: 1 }}
      {...others}>
      <Link to={href} style={{ textDecoration: 'none', width: '100px' }}>
        <Button component="a" disableTouchRipple
          sx={{
            backgroundColor: active ? 'rgba(255,255,255, 0.08)' : '',
            color: active ? '#10B981' : '#6B7A99',
            fontWeight: active ? 'fontWeightBold' : 'normal',
            padding: 0,
            textAlign: 'center',
            textTransform: 'none',
            width: '100%', '& .MuiButton-startIcon': { color: active ? '#10B981' : '#6B7A99' }, '&:hover': { backgroundColor: 'rgba(255,255,255, 0.08)' }
          }}>
          <Box sx={{ flexGrow: 1, fontSize: '13px' }}>
            {title}
          </Box>
        </Button>
      </Link>
    </ListItem>
  );
};

