import { useState, useEffect, useContext } from 'react';
import Table from '@mui/material/Table';
import { styled } from '@mui/system';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { deleteByIdContact, postContact, putContact, getContactsByFarmId } from '../axios';
import {
    Box, Typography
} from '@mui/material';
import { FarmContext } from '../Context/Farm';

interface IContact {
    id: string,
    name: string,
    phoneNumber: string,
    isActive: string,
};

interface IValues {
    name: string,
    phoneNumber: string,
};

interface IPostData {
    name: string,
    phoneNumber: string,
    isActive: string,
    farmId: string,
};

const Root = styled('div')`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    text-align: left;
    padding: 8px;
    font-size: 15px;
    padding: 16px;
  }

  th {
    background-color: #F3F4F6;
    color: #374151;
    font-size: 15px;    
  }
`;

const Contact = () => {
    const [contactList, setContactList] = useState<Array<IContact> | undefined>(undefined);
    const [updateContactId, setUpdateContactId] = useState<string>('');
    const [action, setAction] = useState<any>(undefined);
    const [open, setOpen] = useState<boolean>(false);
    const [active, setActive] = useState<boolean>(true);
    const [values, setValues] = useState<IValues>({
        name: '',
        phoneNumber: '',
    });
    const { selectFarmId } = useContext<IFarmContext>(FarmContext);

    const initFormData = () => {
        setValues({
            name: '',
            phoneNumber: ''
        })
        setActive(true)
    };

    const openContactForm = () => {
        setAction('create');
        setOpen(true);
        initFormData();
    };
    const closeContactForm = () => setOpen(false);
    const getContactFromServer = () => getContactsByFarmId(selectFarmId as string).then(res => setContactList(res.data));

    useEffect(() => {
        init()
    }, [selectFarmId]);

    const init = () => {
        if (selectFarmId) {
            getContactFromServer();
            const timer = setInterval(() => {
                getContactFromServer();
            }, 30000);
            return () => clearTimeout(timer);
        }
    }

    const clickEditButton = (contact: IContact) => {
        setUpdateContactId(contact.id);
        setAction('update');
        setOpen(true)
        setValues({
            name: contact.name,
            phoneNumber: contact.phoneNumber,
        })
    };

    const clickDeleteButton = async (id: string) => {
        const res = await deleteByIdContact(id)
        if (res.data === 'Delete success') {
            getContactFromServer();
        }
    };

    const createContact = async (data: IPostData) => {
        try {
            const res = await postContact(data)
            if (res.data === 'success') {
                getContactFromServer();
                closeContactForm();
            }
        } catch (e: any) {
            console.log('Error', e)
        }
    }

    const updateContact = async (data: IPostData) => {
        try {
            const res = await putContact(data, updateContactId)
            if (res.data === 'Update success') {
                getContactFromServer();
                closeContactForm();
            }
        } catch (e: any) {
            console.log('Error', e)
        }
    }

    const handleChange = (prop: keyof IValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleSubmit = () => {
        if (action === 'create') {
            createContact({ ...values, isActive: active ? '1' : '0', farmId: selectFarmId as string })
        } else if (action === 'update') {
            updateContact({ ...values, isActive: active ? '1' : '0', farmId: selectFarmId as string })
        }
    };


    return <div>
        <Box sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            m: -1
        }}>
            <Typography sx={{ m: 1 }} variant="h5">
                연락처
            </Typography>
            <Box sx={{ m: 1 }}>
                <Button variant="contained" onClick={openContactForm}>
                    연락처 추가
                </Button>
            </Box>
        </Box>

        <Dialog open={open} onClose={closeContactForm}>
            <DialogTitle>연락처 추가</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    추가할 연락처 정보를 입력해주세요.
                </DialogContentText>
                <br />
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="이름"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.name}
                    onChange={handleChange('name')}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="phoneNumber"
                    label="전화번호"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.phoneNumber}
                    onChange={handleChange('phoneNumber')}
                />
                <FormControlLabel
                    control={
                        <Switch checked={active} onChange={() => setActive(!active)} name="isActive" />
                    }
                    label="알람 활성화"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeContactForm()}>취소</Button>
                <Button onClick={handleSubmit}>추가</Button>
            </DialogActions>
        </Dialog>
        {contactList &&
            <Box sx={{ mt: 3 }}>
                <TableContainer component={Paper}>
                    <Root sx={{ maxWidth: '100%' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">이름</TableCell>
                                    <TableCell align="center">전화번호</TableCell>
                                    <TableCell align="center">알람상태</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {contactList.map((contact: IContact) => {
                                    return (
                                        <TableRow
                                            key={contact.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center">{contact.name}</TableCell>
                                            <TableCell align="right">{contact.phoneNumber}</TableCell>
                                            <TableCell align="right">{contact.isActive}</TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    style={{ backgroundColor: "#628CF7", marginRight: '10px' }}
                                                    onClick={() => clickEditButton(contact)}>
                                                    수정
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    style={{ backgroundColor: "#f04141", marginRight: '10px' }}
                                                    onClick={() => clickDeleteButton(contact.id)}>
                                                    삭제
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Root>
                </TableContainer>
            </Box>
        }
    </div>
};
export default Contact;