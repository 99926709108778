import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import { styled } from '@mui/system';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import { getCowStatus, deleteByCowStatus, postCowStatus, putCowStatus } from '../axios';
import {
    Box, Typography
} from '@mui/material';

interface ICowStatus {
    id: string,
    name: string,
};

interface IValues {
    name: string,
};

interface IPostData {
    name: string
};

const Root = styled('div')`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    text-align: left;
    padding: 8px;
    font-size: 15px;
    padding: 16px;
  }

  th {
    background-color: #F3F4F6;
    color: #374151;
    font-size: 15px;    
  }
`;

const CowStatus = () => {
    const [cowStatusList, setCowStatusList] = useState<Array<ICowStatus> | undefined>(undefined);
    const [updateCowStatusId, setUpdateCowStatusId] = useState<string>('');
    const [action, setAction] = useState<any>(undefined);
    const [open, setOpen] = useState<boolean>(false);
    const [values, setValues] = useState<IValues>({
        name: '',
    });

    const initFormData = () => {
        setValues({
            name: '',
        })
    };

    const openCowStatusForm = () => {
        setAction('create');
        setOpen(true);
        initFormData();
    };
    const closeCowStatusForm = () => setOpen(false);
    const getCowStatusFromServer = () => getCowStatus().then(res => setCowStatusList(res.data));

    useEffect(() => {
        getCowStatusFromServer();
        const timer = setInterval(() => {
            getCowStatusFromServer();
        }, 30000);
        return () => clearTimeout(timer);

    }, []);

    const clickEditButton = (cowStatus: ICowStatus) => {
        setUpdateCowStatusId(cowStatus.id);
        setAction('update');
        setOpen(true)
        setValues({
            name: cowStatus.name,
        })
    };

    const clickDeleteButton = async (id: string) => {
        const res = await deleteByCowStatus(id)
        if (res.data === 'Delete success') {
            getCowStatusFromServer();
        }
    };

    const createCowStatus = async (data: IPostData) => {
        try {
            const res = await postCowStatus(data)
            if (res.data === 'success') {
                getCowStatusFromServer();
                closeCowStatusForm();
            }
        } catch (e: any) {
            console.log('Error', e)
        }
    }

    const updateCowStatus = async (data: IPostData) => {
        try {
            const res = await putCowStatus(data, updateCowStatusId)
            if (res.data === 'Update success') {
                getCowStatusFromServer();
                closeCowStatusForm();
            }
        } catch (e: any) {
            console.log('Error', e)
        }
    }

    const handleChange = (prop: keyof IValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleSubmit = () => {
        if (action === 'create') {
            createCowStatus({ ...values })
        } else if (action === 'update') {
            updateCowStatus({ ...values })
        }
    };

    return <div>
        <Box sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            m: -1
        }}>
            <Typography sx={{ m: 1 }} variant="h5">
                소 상태
            </Typography>
            <Box sx={{ m: 1 }}>
                <Button variant="contained" onClick={openCowStatusForm}>
                    소 상태 생성
                </Button>
            </Box>
        </Box>

        <Dialog open={open} onClose={closeCowStatusForm}>
            <DialogTitle>상태 추가</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    추가할 상태의 정보를 입력해주세요.
                </DialogContentText>
                <br />
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="상태 이름"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.name}
                    onChange={handleChange('name')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeCowStatusForm()}>취소</Button>
                <Button onClick={handleSubmit}>추가</Button>
            </DialogActions>
        </Dialog>
        {cowStatusList &&
            <Box sx={{ mt: 3 }}>
                <TableContainer component={Paper}>
                    <Root sx={{ maxWidth: '100%' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">이름</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cowStatusList.map((cowStatus: ICowStatus) => {
                                    return (
                                        <TableRow
                                            key={cowStatus.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center">{cowStatus.name}</TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    style={{ backgroundColor: "#628CF7", marginRight: '10px' }}
                                                    onClick={() => clickEditButton(cowStatus)}>
                                                    수정
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    style={{ backgroundColor: "#f04141", marginRight: '10px' }}
                                                    onClick={() => clickDeleteButton(cowStatus.id)}>
                                                    삭제
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Root>
                </TableContainer>
            </Box>
        }
    </div>
};
export default CowStatus;