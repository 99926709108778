import { useState, useEffect, useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { deleteNsensorById, postNsensor, putNsensor, getSensorStatus, getNsensorsByFarmId } from '../axios';
import { Link } from 'react-router-dom';
import {
    Box, Typography, Card,
    CardContent, InputAdornment,
    SvgIcon,
} from '@mui/material';
import { Search as SearchIcon } from '../icons/search';
import { styled } from '@mui/system';
import { FarmContext } from '../Context/Farm';
import TableSortLabel from '@mui/material/TableSortLabel';

interface ISensorStatus {
    id: string,
    name: string
};

interface IValues {
    serial: string,
    daysInReport: string,
    detail: string,
    sensorId: string
};

interface INsensor {
    id: string,
    serial: string,
    sensorStatusId: string,
    status: string,
    daysInReport: string,
    alarmActive: string,
    sensorId: string,
    cowId: string
};

interface IPostData {
    serial: string,
    sensorStatusId: string,
    daysInReport: string,
    detail: string,
    alarmActive: string,
    sensorId: string,
    farmId: string,
};

const Root = styled('div')`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    text-align: left;
    padding: 8px;
    font-size: 15px;
    padding: 16px;
  }

  th {
    background-color: #F3F4F6;
    color: #374151;
    font-size: 15px;    
  }
`;

const Nsensor = () => {
    const [action, setAction] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [nsensorList, setNsensorList] = useState<Array<INsensor> | undefined>(undefined);
    const [visibleNsensorList, setVisibleNsensorList] = useState<Array<INsensor> | undefined>(undefined);
    const [updateNsensorId, setUpdateNsensorId] = useState<string>('');
    const [sensorStatusList, setSensorStatusList] = useState<Array<ISensorStatus> | undefined>(undefined);
    const [sensorStatus, setSensorStatus] = useState<string>('');
    const [active, setActive] = useState<boolean>(true);
    const [values, setValues] = useState<IValues>({
        serial: '',
        daysInReport: '',
        detail: '',
        sensorId: ''
    });
    const [searchText, setSearchText] = useState<string>('');
    const { selectFarmId } = useContext<IFarmContext>(FarmContext);

    useEffect(() => {
        init()
    }, [selectFarmId]);

    const init = () => {
        if (selectFarmId) {
            getNsensorFromServer();
            getSensorStatus().then((res) => {
                setSensorStatusList(res.data)
            });
            // const timer = setInterval(() => {
            //     getNsensorFromServer();
            // }, 30000);
            // return () => clearTimeout(timer);
        }
    }


    const initFormData = () => {
        setValues({
            serial: '',
            daysInReport: '',
            detail: '',
            sensorId: ''
        })
        setActive(true)
        setSensorStatus('')
    }
    const openNsensorForm = () => {
        setAction('create');
        setOpen(true);
        initFormData();
    }
    const closeNsensorForm = () => setOpen(false)
    const handleChange = (prop: keyof IValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    }
    const clickEditButton = (nsensor: INsensor) => {
        setSensorStatus(nsensor.sensorStatusId)
        setUpdateNsensorId(nsensor.id);
        setAction('update');
        setOpen(true)
        setValues({
            serial: nsensor.serial,
            daysInReport: nsensor.daysInReport,
            detail: '',
            sensorId: nsensor.sensorId
        })
    }
    const handleSubmit = () => {
        if (action === 'create') {
            createNsensor({ ...values, alarmActive: active ? '1' : '0', sensorStatusId: sensorStatus, farmId: selectFarmId as string })
        } else if (action === 'update') {
            updateNsensor({ ...values, alarmActive: active ? '1' : '0', sensorStatusId: sensorStatus, farmId: selectFarmId as string })
        }
    };

    const getNsensorFromServer = () => getNsensorsByFarmId(selectFarmId as string).then(res => { setNsensorList(res.data); setVisibleNsensorList(res.data) })
    const createNsensor = async (data: IPostData) => {
        try {
            const res = await postNsensor(data)
            if (res.data === 'success') {
                getNsensorFromServer();
                closeNsensorForm();
            }
        } catch (e: any) {
            console.log('Error', e)
        }
    }
    const updateNsensor = async (data: IPostData) => {
        try {
            const res = await putNsensor(data, updateNsensorId)
            if (res.data === 'Update success') {
                getNsensorFromServer();
                closeNsensorForm();
            }
        } catch (e: any) {
            console.log('Error', e)
        }
    }

    const deleteNsensor = async (id: string) => {
        const res = await deleteNsensorById(id)
        if (res.data === 'Delete success') {
            getNsensorFromServer();
        }
    }

    const changeSearchText = (e: any) => {
        setSearchText(e.target.value);
        const filterData = nsensorList?.filter((nsensor: INsensor) =>
            nsensor.serial.includes(e.target.value) ||
            nsensor.status.includes(e.target.value) ||
            nsensor.daysInReport.includes(e.target.value) ||
            nsensor.sensorId.includes(e.target.value)
        )
        setVisibleNsensorList(filterData);
    }


    // 데이터 저장 할 상태와 정렬 순서 저장할 상태 정의
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<keyof INsensor>('serial');
    // 데이터 정렬 함수 
    const sortData = (property: keyof INsensor) => {
        if (!visibleNsensorList) return []; // nsensorList가 undefined인 경우 빈 배열 반환

        const isAscending = orderBy === property && order === 'asc';
        const sortedData = visibleNsensorList.slice().sort((a, b) => {
            const valueA = typeof a[property] === 'string' ? isNaN(parseInt(a[property], 10))
                ? a[property] : parseInt(a[property], 10) : a[property];
            const valueB = typeof b[property] === 'string' ? isNaN(parseInt(b[property], 10))
                ? b[property] : parseInt(b[property], 10) : b[property];
            // 아래 코드 한글 않됨;;
            // let valueA = typeof a[property] === 'string' ? parseInt(a[property], 10) : a[property];
            // let valueB = typeof b[property] === 'string' ? parseInt(b[property], 10) : b[property];
            if (valueA < valueB) return isAscending ? -1 : 1;
            if (valueA > valueB) return isAscending ? 1 : -1;
            return 0;
        });
        setVisibleNsensorList(sortedData);
        setOrder(isAscending ? 'desc' : 'asc');
        setOrderBy(property);
    }


    return <div>
        <Box sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            m: -1
        }}>
            <Typography sx={{ m: 1 }} variant="h5">
                목걸이 센서
            </Typography>
            <Box sx={{ m: 1 }}>
                <Button color="info" variant="contained" onClick={openNsensorForm}>
                    목걸이 센서 추가
                </Button>
            </Box>
        </Box>

        <Box sx={{ mt: 3 }}>
            <Card>
                <CardContent>
                    <Box sx={{ maxWidth: 500 }}>
                        <TextField
                            fullWidth
                            value={searchText}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SvgIcon
                                            color="action"
                                            fontSize="small"
                                        >
                                            <SearchIcon />
                                        </SvgIcon>
                                    </InputAdornment>
                                )
                            }}
                            onChange={changeSearchText}
                            placeholder="목걸이 센서 검색"
                            variant="outlined"
                        />
                    </Box>
                </CardContent>
            </Card>
        </Box>

        <Dialog open={open} onClose={closeNsensorForm}>
            <DialogTitle>목걸이 센서 추가</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    목걸이 센서 정보를 입력해주세요.
                </DialogContentText>
                <br />
                <TextField
                    autoFocus
                    margin="dense"
                    id="serial"
                    label="시리얼 넘버"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.serial}
                    onChange={handleChange('serial')}
                />
                <TextField
                    id="outlined-select-status"
                    margin="dense"
                    select
                    label="상태"
                    value={sensorStatus}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSensorStatus(event.target.value)}
                    fullWidth
                >
                    {sensorStatusList && sensorStatusList.map((sensorStatus: ISensorStatus) => (
                        <MenuItem key={sensorStatus.id} value={sensorStatus.id}>
                            {sensorStatus.name}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    autoFocus
                    margin="dense"
                    id="daysInReport"
                    label="Days In Report"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.daysInReport}
                    onChange={handleChange('daysInReport')}
                />

                <TextField
                    autoFocus
                    margin="dense"
                    id="sensorId"
                    label="센서 ID"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={values.sensorId}
                    onChange={handleChange('sensorId')}
                />
                <br />
                <FormControlLabel
                    control={
                        <Switch checked={active} onChange={() => setActive(!active)} name="alarmActive" />
                    }
                    label="알람 활성화"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeNsensorForm()}>취소</Button>
                <Button onClick={handleSubmit}>추가</Button>
            </DialogActions>

        </Dialog>
        {visibleNsensorList &&
            <Box sx={{ mt: 3 }}>
                <TableContainer component={Paper}>
                    <Root>
                        <Table sx={{ minWidth: 650 }} aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            active={orderBy === 'serial'}
                                            direction={order}
                                            onClick={() => sortData('serial')}>
                                            시리얼번호
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === 'status'}
                                            direction={order}
                                            onClick={() => sortData('status')}>
                                            상태
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === 'daysInReport'}
                                            direction={order}
                                            onClick={() => sortData('daysInReport')}>
                                            Days In Report
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === 'alarmActive'}
                                            direction={order}
                                            onClick={() => sortData('alarmActive')}>
                                            알람상태
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === 'sensorId'}
                                            direction={order}
                                            onClick={() => sortData('sensorId')}>
                                            센서ID
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right">
                                        <TableSortLabel
                                            active={orderBy === 'cowId'}
                                            direction={order}
                                            onClick={() => sortData('cowId')}>
                                            소ID
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {visibleNsensorList.map((nsensor: INsensor) => {
                                    return (
                                        <TableRow
                                            key={nsensor.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell>
                                                <Link
                                                    to={`/dashboard/nsensorDetail/${nsensor.id}`}
                                                    style={{ textDecoration: 'none' }}>
                                                    {nsensor.serial}
                                                </Link>
                                            </TableCell>
                                            <TableCell align="right">{nsensor.status}</TableCell>
                                            <TableCell align="right">{nsensor.daysInReport}</TableCell>
                                            <TableCell align="right">{nsensor.alarmActive}</TableCell>
                                            <TableCell align="right">{nsensor.sensorId}</TableCell>
                                            <TableCell align="right">{nsensor.cowId}</TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    style={{ backgroundColor: "#628CF7", marginRight: '10px' }}
                                                    onClick={() => clickEditButton(nsensor)}>
                                                    수정
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    style={{ backgroundColor: "#f04141", marginRight: '10px' }}
                                                    onClick={() => deleteNsensor(nsensor.id)}>
                                                    삭제
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                )}
                            </TableBody>
                        </Table>
                    </Root>
                </TableContainer>
            </Box>
        }
    </div >
};

export default Nsensor;